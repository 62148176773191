.custom__breadCrumb__wrap-content-column {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 20px;
}

.custom__breadCrumb__wrap-content-column-custom {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
   margin-top: 30px;
}

.custom__breadCrumb__wrap-content-column-customRuleEngine {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
   margin-top: 30px;
   margin-bottom: 5px;
   border-bottom: 1px solid black;
}

.custom__breadCrumb__wrap-content-column .link__btn-text {
   font-size: 14px;
}