.add__station {
    padding: 20px 60px 20px 20px;
    height: 100%;
}

.add__station__container {
    height: 90%;
}

.add__station__from__wrap {
    padding: 15px 0px;
}

.add__station .rms__dropdown__control {
    min-height: 32px;
    border: 1px solid #B2BCBD;
}

.add__station__header {
    padding: 20px 0px;
}

.rms__custom__button .rms__form__primary__btn {
    height: 36px;
    width: 63px;
    margin-right: 7px;
}

.rms__custom__button .rms__form__secondary__btn {
    height: 36px;
    width: 84px;
    border: 1px solid var(--secondary-blue-color);
    background-color: #fff;
}

.rms__custom__button .rms__form__secondary__btn:hover {
    background-color: #fff;
    color: var(--secondary-blue-color);
}

.add__station--footer {
    margin-top: auto;
    position: relative;
    width: 100%;
    padding-top: 10px;
}

.rms-form-label {
    font-size: 12px !important;
    display: flex;
    margin: 6px 0px !important;
    color: #002126 !important;
    letter-spacing: -0.01px !important;
    font-family: var(--font-family-regular) !important;
}

.charging__station__customer__option {
    display: flex;
    justify-content: space-between;
}


.charging__station__action__icons__wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.charging__station__form__wrap-content {
    margin: 10px 0px;
    height: 100%;
    overflow-y: auto;
}

.add__station__form__wrap {
    padding: 16px 20px;
    min-height: calc(100dvh - 48px);
    height: calc(100dvh - 48px);
    display: flex;
    flex-direction: column;
}

.add__station__form__wrap .custom__form__wrap {
    padding: unset;
    min-height: auto;
    height: auto;
}

.charging__station__table .rms__table__dashboard__search__wrap-right {
    grid-template-columns: repeat(2, minmax(180px, 1fr));
}

.individual__station__text {
    color: #3dcdcd;
    font-size: 12px;
    font-family: var(--font-family-regular);
    letter-spacing: -0.01px;
    font-weight: 500;
    cursor: pointer;
}
.add__station__header{
    display: flex;
    justify-content: space-between;
}
.chargingStation__form__wrap-header-wrap-buttons {
    display: flex;
    height: 32px;

  }
  .station-edit-view-icons{
    padding: 7px;
  }

  .chargingStation__dashboard_link-text{
    color: #3dcdcd;
    font-size: 12px;
    font-family: var(--font-family-regular);
    letter-spacing: -0.01px;
    font-weight: 500;
    cursor: pointer;
  }