.grid__layout__wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  overflow-y: auto;
}

.grid__expandable__layout {
  margin-bottom: 20px;
}

.grid__layout__expandable__container {
  width: 100%;
  display: flex;
  height: 58px;
  align-items: center;
  border: 1px solid var(--grid-layout-border);
  border-radius: 6px;
  box-shadow: 2px 4px 8px #9c9c9c1a;
  padding: 0px 32px;
  margin-bottom: 5px;
}

.grid__layout__expandable__container-header-wrap {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}

.grid__layout__expandable__container-header-text.bold {
  font-weight: 500;
  font-family: var(--font-family-semi-bold);
}

.grid__layout__expandable__container-header-text {
  font-size: 16px;
  color: var(--primary-text-color);
  font-family: var(--font-family-semi-medium);
}

.grid__layout__expandable__container-header-number {
  border-radius: 8px;
  color: var(--primary-text-color);
  font-size: 16px;
  margin-left: 8px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 40px;
  max-height: 40px;
}

.grid__layout__wrap .rms__table__dashboard__footer {
  padding-top: 0px;
}

.grid__layout__expandable__container.is__expanded {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: none;
  margin-bottom: 0px;
}

.grid__layout__table__wrap {
  display: flex;
  overflow: hidden;
}

.grid__layout__expandable__container__non__toggle__wrap {
  width: 97%;
  height: 100%;
  display: flex;
  align-items: center;
}

.grid__layout__expandable__container__toggle__wrap {
  width:3%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.grid__layout__expandable__container-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid__layout__table .MuiDataGrid-main {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  background-color: var(--primary-white-color);
}
