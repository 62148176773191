.rms__login__container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-white-color);
  box-sizing: border-box;
  flex-direction: column;
}

.rms__login__card {
  background: #ffffff;
  box-shadow: 0px 4px 8px #00000029;
  border-radius: 8px;
  padding: 50px;
  width: 430px;
  min-height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.rms__login__logo-wrap {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rms__login-header {
  color: var(--primary-text-color);
  font-family: var(--font-family);
  font-size: 16px;
  padding-top: 40px;
  text-align: center;
  line-height: 1.5;
  font-weight: 400;
  padding-bottom: 10px;
}

.rms__login__form__field__wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 16px;
}

.rms__login__form__field-text {
  color: var(--primary-text-color);
  font-family: var(--font-family);
  font-size: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.rms__login__form__field {
  width: 100%;
}

.rms__login__form-button-wrap {
  width: 100%;
}

.rms__login__form__field-input {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.react-tel-input .form-control {
  font-size: 14px;
  padding: 15px 15px 15px 50px !important;
  width: 100% !important;
}

.react-tel-input .form-control:focus {
  border-color: var(--secondary-blue-color) !important;
  box-shadow: none !important;
}

.rms__login__form__field-input .react-tel-input .form-control.invalid-number {
  border: 1px solid var(--red-color);
}

.rms__login__form__error__message {
  font-size: 10px;
  font-weight: 500;
  font-family: var(--font-family);
  color: var(--red-color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8px;
  height: 30px;
}

.rms__login__btn {
  color: var(--primary-white-color) !important;
  width: 100% !important;
  border: 1px solid var(--secondary-blue-color) !important;
}

.rms__otp__field .MuiInputBase-input {
  padding: 8px !important;
  font-family: var(--font-family);
}

.rms__otp__field .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
  border-width: 1px !important;
}

.rms__login__btn.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.rms__login__form-resend-otp-wrap{
  height: 40px;
}

.rms__login__form-resend-otp {
  font-size: 14px;
  color: var(--secondary-blue-color);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  cursor: pointer;
  background: transparent;
  border: 0px;
}

.rms__login__form-resend-otp.disabled {
  pointer-events: none;
  color: var(--grey-color);
}


.rms__login__form-resend-otp:focus {
  outline: revert;
}

.rms__login__form-login-edit-phone-number{
  font-size: 14px;
  color: var(--secondary-blue-color);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  cursor: pointer;
  background: transparent;
  border: 0px;
}
