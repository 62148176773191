.app__layout {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}


/* Navbar style Starts...................................................................................... */

.app__navbar__profile {
  display: flex;
  margin-left: 20px;
  height: 100%;
  align-items: center;
}

.app__navbar__profile:hover {
  cursor: pointer;
}

.app__navbar__right {
  display: flex;
  padding-right: 25px;
  /* width: 230px; */
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.app__navbar__left {
  width: 230px;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding-left: 24px;
}

.app__navbar__search__wrap {
  width: calc(100% - 512px);
  display: flex;
}

.profile__list {
  padding: 0px !important;
}

.navbar__profile__arrows__wrap {
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}




/* Sidebar style Starts...................................................................................... */

.rms__sidebar__item:hover {
  border-left: 3px solid var(--secondary-blue-color);
}

.rms__sidebar__item.selected {
  border-left: 3px solid var(--secondary-blue-color);
  background-color: rgba(0, 0, 0, 0.04);
}

.rms__nested__menu__wrapper .rms__sidebar__item.selected .MuiTypography-root {
  color: var(--secondary-blue-color) !important;
  font-weight: 500;
}

.rms__sidebar__item.selected .rms__sidebar__text {
  font-weight: 500 !important;
}


.rms__nested__menu__wrapper .rms__sidebar__item.selected {
  border-left: 3px solid var(--secondary-blue-color);
  background-color: transparent;
}

.rms__nested__menu__wrapper {
  background-color: rgba(0, 0, 0, 0.04);
}


.rms__sidebar__item {
  border-left: 3px solid var(--sidebar-bg-color);
}

.app__sidebar .side__bar__collapse__wrap .rms__sidebar__item:hover .rms__sidebar__text {
  color: var(--secondary-blue-color) !important;
}



/* Dashboard style Starts...................................................................................... */

.app__layout__container {
  height: calc(100dvh - 48px);
  display: flex;
  margin-top: 48px;
  width: 100%;

}

.app__layout .MuiAppBar-root {
  box-shadow: none !important;
}


.app__route__container {
  height: calc(100vh - 52px);
  width: calc(100vw - 230px);
  margin-left: 230px;
  display: flex;
  flex-direction: column;
}

.app__route__container.without__side__bar {
  margin-left: 0px !important;
  width: 100%;
}

.app__version{
  font-size: 12px;
  color: var(--primary-white-color);
}