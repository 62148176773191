.charger__form__wrap {
    padding: 16px 20px;
    min-height: calc(100dvh - 48px);
    height: calc(100dvh - 48px);
    display: flex;
    flex-direction: column;
  }

.charger__form__wrap-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
  }
  .charger__form_header-status-info {
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: auto;
  }

  .charger__form__wrap-content {
    margin: 10px 0px;
    height: 100%;
    overflow-y: auto;
  }
  .charger__form__wrap-content .custom__form__wrap {
    padding: unset;
    min-height: auto;
    height: auto;
  }
  
  .charger__form__wrap-footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    position: relative;
    width: 100%;
    padding-top: 10px;
  }
 
  .charger__bulk__upload__side__popover .side__filter__wrap-content {
    display: flex;
    height: 100%;
  }

  .charger__bulk__upload__side__popover .bulk-chargers-container {
    display: flex;
    flex-direction: column;
  }
  .charger__bulk__upload__side__popover .bulk_charger-upload-buttons {
    margin-top: auto;
    bottom: 40px;
    position: sticky;
    background-color: white;
    width: 100%;
    padding: 20px 10px;
  }

  .charger__bulk__upload__side__popover .side__filter__wrap-header{
    /* justify-content: center; */
    align-items: center;
  }

  .charger_example_template{
    border: 22px black;
    border-radius:12px ;
    background-color:#f0fbfb;
    padding: 15px;
  }
  .charger_info_drop_down{
    display: flex;
    padding-bottom: 5px;
    cursor: pointer;
  }
  .charger-info-heading{
    padding-left: 5px;
    font-size: 13px;
 
  }
  .charger_download_template{
    padding-bottom: 0px;
  }
  .timeline_container{
    padding: 0 !important;
  }

  .timeLine__dot,
  .timeLine__connector {
    background-color: var(--secondary-blue-color) !important;
    padding: 0;
  }
  
  .bulk-charger-upload label {
    border: 2px dashed grey !important;
  }

  .bulk-charger-upload svg {
   display: none;
  }