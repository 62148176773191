.notification__status__action__email__wrapper{
    border: 1px solid var(--grey-color);
    border-radius: 4px;
    padding: 20px 10px;
}

.notification__status__action__country__and__sms__wrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.notification__action__status__entered__email__wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    gap: 8px;
}

.notification__action__side__popover__wrapper{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.notification__action__recipients__chips{
    background-color: rgba(0,0,0,0.87);
    border-radius:  16px;
    font-size: 14px;
    font-weight: 500;
    padding: 4px;
}

.notification__action__row__label {
    font-size: 12px;
    font-family: var(--font-family-regular);
    color: var(--primary-text-color);
    padding: 0px 0px 5px 2px;
}
.row__label{
    display: flex;
    gap: 8px;
}
.notification__action__row__label{
    font-size: 14px;
    color: var(--primary-text-color);
}

.notification__action__input__phone__wrapper .react-tel-input .form-control{
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}

.notification__action__input__phone__wrapper{
    border: 1px solid var(--grey-color);
    border-radius: 4px;
    padding: 5px 10px 20px 10px;
}

.react-tel-input input::placeholder {
    color: black;
    opacity: 1 !important;
  }

/* .form-control {
    background-color: red;
    z-index: -2;
} */

/* .form-control input::placeholder {
    color: red !important;
    z-index: 2 !important;
} */

.notification__action__side__popover__constant{
    font-size: 12px;
    font-family:  var(--font-family-regular);
    padding-top: 10px;
    color: var(--text-grey-color)
}