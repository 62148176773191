.configure__notification__history__link-text{
    color: #3dcdcd;
    font-size: 12px;
    font-family: var(--font-family-regular);
    letter-spacing: -0.01px;
    font-weight: 500;
    cursor: pointer;
  }

  .configure__notification__history__alarm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
 }