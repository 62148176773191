.rule__engine__define__execution__selected__parameters {
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    color: black;
}

.transparent-handle {
    background-color: transparent !important;
    /* Makes the handle transparent */
    border: none !important;
    /* Removes the border */
}