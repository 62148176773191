.custom__form__wrap.edit__parameter__wrap {
  height: auto !important;
  min-height: max-content;
  padding: 0;
}

.edit__parameter__dashboard {
  padding: 16px 0px;
  overflow-y: auto;
}

.edit__parameter__btns {
  
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.edit__parameter__parameters__list {
  overflow-y: auto;
  max-height: 48vh;
  margin-top: 20px;
}


.edit__parameter__single__parameters__container {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding-bottom: .5px;
  margin-top: 5px;
  padding-right: 9px;
}

.edit__parameter__toggle__wrap {
  background-color: var(--white-color);
  box-shadow: 2px 4px 8px #9C9C9C1A;
  cursor: pointer;
  border: 1px solid var(--grid-layout-border);
  border-radius: 6px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.edit__parameter__toggle__wrap-text {
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--font-family-semi-bold);
  letter-spacing: -0.01px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.edit__parameter__toggle__wrap-arrow {
  margin-left: auto;
  cursor: pointer;
}

.edit__parameter__toggle__wrap__content {
  box-shadow: 2px 4px 8px #9C9C9C1A;
  border-top: 0px;
  background: var(--primary-white-color);
}

.edit__parameter__toggle__wrap__content-row__wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit__parameter__toggle__wrap__content-row-parameter-wrap {
  color: black;
  font-size: 12px;
  padding-bottom: 1vh;
  white-space: nowrap;
  display: flex;
}

.edit__parameter__toggle__wrap__content-row-value-wrap {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;

  overflow-wrap: anywhere;
  color: var(--alarm-summary-header-color);
  font-size: 14px;
  font-weight: 300;
}


.edit__parameter__single__parameters__container .add-parameter-dropdown-options {
  width: 27%;
}


/* css of date and time  */

.custom__date__picker__wrap__edit__parameter {
  margin-bottom: 1vh;
}

.custom-datepicker {
  border: 0px solid black;
  border-radius: 4px;
  outline: none;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}
edit

.edit__parameter__single__parameters__container .rms__custom__input__field {
  width: 27%;
}

.edit__parameter__btns .send-now-and-later-box {
  display: flex;
  flex-direction: row;
  padding-bottom: 1vh;
}

.edit__parameter__btns .edit-parameter-scheduled-date {
  padding-top: 1px;
}

.custom__form__wrap.edit__parameter__wrap .rms__dropdown__multi-value {
  border-radius: 12px !important;
  max-width: fit-content !important;
}

.manage__parameter__table .rms__table__dashboard__search__wrap-right {
  grid-template-columns: repeat(4, minmax(180px, 180px));
}

.edit-parameter-content-wrap {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  height: 100%;
}


.custom__form__wrap-content-row.edit-parameter-schedule-date {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  font-weight: 600;
}

.edit-parameter-content-wrap-top{
  display: flex;
  flex-direction: column;
}

.edit-parameter-single-parameter-update .add__parameter__toggle__wrap {
  background-color: var(--white-color);
  box-shadow: 2px 4px 8px #9C9C9C1A;
  cursor: pointer;
  border: 1px solid var(--grid-layout-border);
  border-radius: 6px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
}


.edit-parameter-single-parameter-update .add__parameter__single__parameters__container {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 40%;
  padding-bottom: .5px;
  margin-top: 5px;
  padding-right: 9px;
}

.edit-parameter-content-wrap-top .add__parameter__single__parameters__container .add-parameter-dropdown-options{
 width: 80%;
}

.edit-parameter-content-wrap-top .add__parameter__single__parameters__container .rms__custom__input__field{
width: 80%;
}

