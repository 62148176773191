.app__dashboard__header-right {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2,minmax(180px, 1fr));
}

.app__dashboard__header-left {
  display: flex;
  /* align-items: flex-end; */
  justify-content: center;
  flex-direction: column;
}

.app__dashboard__header-left .main__dashboard__header {
  padding-bottom: 5px;
}

.app__dashboard__header-right .header__filter__btn {
  color: var(--primary-text-color);
}

.app__dashboard__header-dropdowns {
  min-width: 150px;
  margin-right: 20px;
}

.app__dashboard__header-grey-text {
  color: var(--placeholder-color);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.app__dashboard__header-blue-text {
  color: var(--secondary-blue-color);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.12px;
  margin-bottom: 5px;
  margin-left: 2px;
  margin-top: 2px;
}

.app__dashboard__header-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 0px 20px 20px 20px;
  width: 100%;
}

.app__dashboard__header-dropdown-label {
  font-size: 12px;
  font-family: var(--font-family);
  letter-spacing: -0.01px;
  color: var(--primary-text-color);
  margin-bottom: 5px;
}

.app__dashboard__header-card {
  min-height: 110px;
  min-width: 155px;
  height: 110px;
  cursor: pointer;
}

.app__dashboard__header-card:last-of-type {
  margin-right: 0px;
}

.app__dashboard__header-cards .card {
  display: flex;
  padding: 15px 15px 15px 15px;
  border-radius: 10px;
  flex-direction: column;
  height: 100%;
}

.app__dashboard__header-cards .card:hover {
  background-color: var(--white-color) !important;
  box-shadow: 0px 0px 4px #00000033;
}

.app__dashboard__header-cards .card__header {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.app__dashboard__header-cards .card__header__count {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  line-height: 1.167;
  letter-spacing: -0.01562em;
  font-family: var(--font-family-bold);
  color: var(--primary-text-color);
  font-size: 40px;
  font-weight: 500;
}

.app__dashboard__header-cards .card__footer__wrap {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.app__dashboard__header-cards .card__header__count {
  margin-top: 0px;
}

.app__dashboard__header-cards .card__header {
  display: flex;
}

.card__header__text .MuiIconButton-root {
  padding: 0px;
}

.card__header__icon {
  padding: 0px !important;
}

.card__header__text {
  align-items: center;
  font-size: 14px !important;
  font-weight: 400 !important;
  white-space: nowrap;
  display: flex;
  margin-left: 8px;
  line-height: 1.334;
  letter-spacing: 0em;
}

.app__dashboard__body-container {
  background-color: var(--primary-white-color);
  display: flex;
  padding: 20px;
}

.app__dashboard__charts__body-container {
  background-color: var(--primary-white-color);
  display: flex;
}

.app__dashboard__charts__body-container > * {
  /* flex-grow: 0; */
  display: flex;
}

.app__dashboard__charts__body-container > :first-child {
  width: 30%;
}

.app__dashboard__charts__body-container > :last-child {
  width: 70%;
}

.charger__health__status__container {
  width: 50%;
  border-radius: 8px;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 4px -2px rgba(0, 0, 0, 0.005),
    0px 8px 6px -3px rgba(0, 0, 0, 0.04), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
}

.charger__health__status-graph-wrap {
  padding: 0px 40px;
  display: contents;
}

.charger__health__status-graph-wrap .highcharts-container {
  border-radius: 8px !important;
}

.charger__health__status-header-wrap {
  display: flex;
  align-items: center;
  padding: 28px 20px 20px;
}

.charger__health__status-header {
  color: var(--primary-text-color);
  font-size: 18px;
  font-weight: 600;
}

.charger__health__status-header-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
}

.alarm__summary__container {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--white-color);
  margin-left: 20px;
  box-shadow: 0px 6px 4px -2px rgba(0, 0, 0, 0.005),
    0px 8px 6px -3px rgba(0, 0, 0, 0.04), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
}

.alarm__summary-header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.alarm__summary-header {
  color: var(--primary-text-color);
  font-size: 18px;
  font-weight: 600;
}

.alarm__summary-cards-wrap {
  padding: 5px 20px 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
}

.alarm__summary-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 0px !important;
}

.alarm__summary-card-header {
  font-size: 14px;
  font-weight: 400;
  padding-left: 8px;
  padding-top: 8px;
}

.alarm__summary-card-count {
  color: #565656;
  font-size: 20px;
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.alarm__summary-table {
  margin: 0px 20px 20px;
  height: 240px;
  overflow-y: auto;
}

.alarm__summary__name {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.alarm__summary__name.icon__severity {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 5px;
}

.alarm__summary__charger__id {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.alarm__summary-table-wrap {
  box-shadow: none !important;
}

.alarm__summary-table-wrap .MuiTable-root {
  border-collapse: separate !important;
  border-spacing: 0px 3px !important;
}

.charger__working__status__container {
  width: 100%;
  display: flex;
  background-color: var(--primary-white-color);
  padding: 0px 20px 30px 0px;
  /* box-shadow: 0px 6px 4px -2px rgba(0, 0, 0, 0.005),
    0px 8px 6px -3px rgba(0, 0, 0, 0.04), 0px 1px 5px 0px rgba(0, 0, 0, 0.05); */
}

.connectivity__medium__status__container {
    width: 100%;
    display: flex;
    background-color: var(--primary-white-color);
    padding: 0px 20px 30px 20px;
}

.connectivity__status__wrap {
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 6px 4px -2px rgba(0, 0, 0, 0.005),
  0px 8px 6px -3px rgba(0, 0, 0, 0.04), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.charger__working__status__wrap {
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 6px 4px -2px rgba(0, 0, 0, 0.005),
  0px 8px 6px -3px rgba(0, 0, 0, 0.04), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.charger__working__status-header-wrap {
  padding: 20px;
}

.charger__working__status-header {
  color: var(--primary-text-color);
  font-size: 18px;
  font-weight: 600;
}

.charger__working__status-header.blue__text {
  color: var(--secondary-blue-color);
  font-size: 16px;
  font-weight: 500;
}

.charger__working__status-header.grey__text {
  color: var(--text-grey-color);
  font-size: 16px;
  font-weight: 500;
}

.customer__wise__dropdown__wrap {
  min-width: 200px;
}

.alarm__summary-dropdown {
  min-width: 120px;
}

.charger__working__status-graph-wrap {
  position: relative;
  min-height: 300px;
}

.connectivity__medium__status-graph-wrap {
  position: relative;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connectivity__medium__status {
  padding-top: 8px;
  color: var(--primary-text-color);
  font-size: 14px;
  font-family: var(--font-family-regular);
}

.charger___working__status__label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.charger___working__status__label .carousel__btn {
  padding: 0px !important;
}

.charger___working__status__label__name {
  margin-top: 8px;
}

.graph__tooltip__container {
  background-color: var(--primary-white-color);
  border: 1px solid var(--grid-layout-border);
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  z-index: 999999999 !important;
}

.graph__tooltip__header {
  letter-spacing: -0.01px;
  color: var(--primary-text-color);
  font-family: var(--font-family-bold);
  font-size: 16px;
  font-weight: 600;
}

.graph__tooltip__total__count {
  letter-spacing: -0.01px;
  color: var(--primary-text-color);
  font-family: var(--font-family-semi-medium);
  font-size: 14px;
  padding-top: 10px;
  overflow: hidden;
}

.graph__tooltip__total__count_right {
  float: right;
}

.graph__tooltip__total___content {
  padding-top: 10px;
}

.graph__tooltip__total___content-row {
  display: flex;
  justify-content: space-between;
  padding: 2px 0px;
  align-items: center;
}

.graph__tooltip__total___content-left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 30px;
}

.graph__tooltip__total___content-icon {
  padding: 0px !important;
  all: unset;
}

.graph__tooltip__total___content-charger__text {
  letter-spacing: -0.01px;
  color: var(--primary-text-color);
  font-family: var(--font-family-regular);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph__tooltip__total___content-customer__text {
  letter-spacing: -0.01px;
  color: var(--primary-text-color);
  font-family: var(--font-family-regular);
  font-size: 12px;
}

.customer__wise__charger__graph__tooltip .graph__tooltip__total___content-charger__text {
  text-transform: capitalize;
  padding-left: 5px;
}

.graph__tooltip__customer__health__status__icon-wrapper {
  padding: 0px !important;
  all: unset;
}

.charger__health__status__tooltip .graph__tooltip__total___content-charger__text {
  padding: 0px 5px;
}

.graph__tooltip__total___content-icon-wrapper {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alarm__summary__table-dashboard-row {
  cursor: pointer;
}

.charger__health__status-graph-skeleton {
  padding: 0% 5% 5% !important;
  min-height: 200px;
}

.charger__working__status-graph-skeleton {
  padding: 0% 16px 16px !important;
}

.dashboard__header__skeleton__wrap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 16px 20px 16px 20px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.dashboard__header__skeleton {
  height: 110px !important;
  padding: 0% !important;
}

.dashboard__header__skeleton .rectangular__skeleton {
  border-radius: 10px !important;
}

.alarm__card__skeleton__wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 10px;
  column-gap: 10px;
  padding: 5px 20px 16px;
}

.alarm__card__skeleton__wrap .alarm__card__skeleton .rectangular__skeleton {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: 50px;
}

.alarm__table__skeleton__header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  padding-bottom: 5px;
}

.alarm__table__skeleton__header .rectangular__skeleton {
  border-radius: 45px !important;
}

.alarm__table__skeleton__content {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  row-gap: 10px;
  padding-top: 5px;
}

.alarm__table__skeleton__row {
  display: grid;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 0.25fr;
  column-gap: 10px;
}

.alarm__table__skeleton__container .rectangular__skeleton__wrapper {
  padding: 0% !important;
  height: 35px !important;
}

.circular__skeleton__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alarm__table__cell .rectangular__skeleton {
  border-radius: 45px !important;
}

.rectangular__skeleton__wrapper.alarm__table__cell-circle .rectangular__skeleton {
  border-radius: 10px !important;
}


.dashboard__last__refresh__time__skeleton.rectangular__skeleton__wrapper {
  padding: 0px !important;
  height: 30px;
  width: 100px;
  margin-right: 10px;
}

.dashboard__last__refresh__time__skeleton.rectangular__skeleton__wrapper .rectangular__skeleton {
  border-radius: 4px;

}


.app__dashboard__body-container .alarm__summary-cards-wrap .alarm__summary-card {
  cursor: pointer;
}

.app__dashboard__header-bottom-auto-refresh-wrap {
  display: flex;
  /* padding-right: 16px; */
}

.app__dashboard__header-bottom-filter-btn-wrap #add_filter_btn {
  color: var(--primary-text-color) !important;
  padding: 6px 16px !important;
}

.auto__refresh__component-dropdown-wrap .rms__dropdown__control {
  font-size: 13px !important;
}

.auto__refresh__component-dropdown-wrap .rms__dropdown__input {
  caret-color: transparent !important;
}

.auto__refresh__component-dropdown-wrap .rms__dropdown__control--is-focused {
  box-shadow: none !important;
}

.dashboard__alarm__header__alarm-icon {
  padding-left: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__alarm__header-severity {
  padding: 0px !important;
}

.alarm__summary-header-subtext {
  padding-left: 8px;
  font-size: 10px;
  font-weight: 400;
}

.custom-arrow {
  cursor: pointer;
  font-size: 14px; 
  fill: rgb(0, 0, 0); 
  border-radius: 100%; 
  width: 30px; 
  height: 30px;
  display: flex;
  z-index: 2; /* Set a higher z-index value */
  position: relative; /* Set positioning to relative or absolute */
}

.custom-arrow .highcharts-button-box {
  rx: 50% !important; 
  ry: 50% !important;
  overflow: hidden !important;
}
.custom-arrow .highcharts-button:hover {
  background-color: gray !important; 
}

.app__dashboard__header-right .rms__dropdown__multi-value {
  max-width: fit-content !important;
}