.dndflow {
    flex-direction: row;
    display: flex;
    flex-grow: 1;
    /* height: 100%; */
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
    box-shadow: 0px 6px 4px -2px rgba(0, 0, 0, 0.005), 0px 8px 6px 3px rgba(0, 0, 0, 0.04), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
}

.dndflow aside .description {
    margin-bottom: 15px;
}

.dndflow .dndnode {
    height: 40px;
    padding: 4px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    gap: 7px;
    align-items: center;
    cursor: grab;
    background-color: #f2eded;
    font-size: 15px;
    font-weight: 400;
    max-width: 260px;
}

.dndflow .reactflow-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

.dndflow .selectall {
    margin-top: 10px;
}


/*SideBar*/
.sidebar {
    padding: 20px 0 20px 20px !important;
    width: 300px;
    background-color: #f9f9f9;
    height: max-content;
    /* margin: 10px; */
}

.description {
    font-size: 14px;
    color: #999;
    margin-bottom: 15px;
}

.section {
    margin-bottom: 17px;
}

.section-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #454545;
    display: flex;
    align-items: center;
}

.section-icon {
    font-size: 18px;
    margin-right: 8px;
}

.dndnode {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 6px;
    cursor: grab;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease;
}

.dndnode:hover {
    background-color: #e6e6e6;
}

.dndnode i {
    font-size: 16px;
    margin-left: 12px;
}

.custom-margin {
    margin-top: 5.5px;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.flexGap {
    display: flex;
    gap: 8px;
}

.rule__engine__canvas__footer {
    position: sticky;
    width: min-content;
    align-self: flex-end;
    margin-top: -60px;
    bottom: 0;
    z-index: 10;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
}

.node_text {
    font-weight: 400;
    font-size: 13px;
    color: #002126;

}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.disabled-node {
    opacity: 0.5;
    /* Visual indicator */
    pointer-events: none;
    /* Block user clicks, but allows dragging */
    cursor: not-allowed;
}

.react-flow__controls-button:last-child {
    display: none;
}