.custom__form__wrap {
  padding: 16px 20px;
  min-height: calc(100dvh - 48px);
  height: calc(100dvh - 48px);
  display: flex;
  flex-direction: column;
}

.custom__form__wrap-header-text {
  padding: 20px 0px 5px;
  font-weight: 300;
  font-size: 14px;
}

.custom__form__wrap-header-text-title {
  padding: 20px 0px 5px;
  font-weight: 300;
  font-size: 17px;
  font-weight: 500;
}

.custom__form__wrap-content-row {
  display: grid;
  padding: 15px 0px 5px;
}

.custom__form__wrap-content-row.grid-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
}

.custom__form__wrap-content-row.grid-2 {
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
}

.custom__form__wrap-content-row.grid-2-custom {
  grid-template-columns: 1fr;
  grid-column-gap: 15px;
  align-items: center;
  padding: 0;
  max-width: 57px;
}

.custom__form__wrap-content-row.grid-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
}

.custom__form__wrap-content-row.grid-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 15px;
}

.custom__form__wrap-footer {
  margin-top: auto;
}

.add__charger__back__btn,
.add__parameter__back__btn {
  width: fit-content;
}

.custom__form__wrap-content-row .rms__custom__input__field #add_customer_email {
  width: 66.67% !important;

}

.custom__form__wrap-content-column .react-tel-input .form-control:disabled {
  background-color: hsl(0, 0%, 95%);
  color: hsl(0, 0%, 60%);
}

.flex-tooltip {
  display: flex;

}

.form-control.custom__form-mobile-input {
  height: 32px !important;
}

.form-control.custom__form-mobile-input:focus,
.form-control.custom__form-mobile-input:hover {
  border-color: var(--secondary-blue-color) !important;
}

.custom__form__fixed__width__label{
min-width: 33%;
}