.charger__panel__status__dashboard {
    display: flex;
    padding: 16px 0px;
    overflow-y: auto;
    flex-direction: column;
}

.charger__status__details__wrap__container {
    display: flex;
    flex-direction: row;
    align-items: start;
    padding-right: 16px;
}

.charger__status__details__wrap {
    display: flex;
    flex-direction: column;
    width: 160px;

}

.charger__panel__status__dashboard-top {
    display: flex;
    /* overflow-x: hidden; */
}

.charger__status__parameters__table {
    padding: 16px 0px;
    overflow-y: auto;
}

.charger__status__connector__wrap {
    width: 112px;
}

.charger__status__connector__wrap-top {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.charger__status__connector__wrap-top.idle {
    background-color: var(--charger-status-idle-light-color);
}

.charger__status__connector__wrap-top.bluecolor {
    background-color: var(--charger-status-blue-light-color);
}


.charger__status__connector__wrap-top .text {
    font-size: 12px;
    font-family: var(--font-family-semi-bold);
    color: var(--primary-text-color);
    letter-spacing: -0.01px;
    font-weight: 500;
}

.charger__status__connector__wrap-top.charging {
    background-color: var(--charger-status-charging-light-color);
}

.charger__status__connector__wrap-bottom {
    height: 103px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-direction: column;
    padding: 8px;

}

.charger__status__connector__wrap-bottom .text {
    font-size: 12px;
    font-family: var(--font-family-semi-bold);
    color: var(--white-color);
    letter-spacing: -0.01px;
    font-weight: 500;
}

.charger__status__connector__wrap-bottom.idle {
    background-color: var(--charger-status-idle-dark-color);
}

.charger__status__connector__wrap-bottom.charging {
    background-color: var(--charger-status-charging-dark-color);
}

.charger__status__connector__wrap-bottom.bluecolor {
    background-color: var(--charger-status-blue-dark-color);
}

.charger__status__connector__wrap-bottom-left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.charger__status__connector__wrap-bottom-right {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.charger__status__connector__wrap-bottom-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.charger__status__connector__wrap-bottom-right .connector__icon {
    padding-top: 8px;
}

.charger__status__details-top {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
}

.charger__status__details-top-first {
    display: flex;
    width: 100%;
}

.charger__status__charger__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 148px;
    margin-left: auto;
    margin-right: -30px;
}


.charger__status__skeleton .charger__status__charger__wrap-skeleton {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    align-items: center;
    justify-content: flex-end;
    height: 148px;
}

.charger__status__charger__connectivity {
    font-size: 13px;
    font-family: var(--font-family-regular);
    color: var(--primary-text-color);
    letter-spacing: -0.01px;
    font-weight: 500;
}

.charger__status__charger__icon__wrap {
    height: 125px;
    width: 58px;
    display: flex;
}

.charger__status__charger__icon__wrap .charger__icon {
    max-width: 100%;
    max-height: 100%;
}

.charger__panel__status__table__wrap {
    display: flex;
    flex-direction: column;
    padding: 8px;
    max-height: 450px;
    overflow-y: scroll;

}

.charger__panel__status__table__wrap::-webkit-scrollbar {
    width: 4px;
    background: black !important;
    height: 4px;

}

.charger__panel__status__table__wrap.bluecolor::-webkit-scrollbar-track {
    background: var(--charger-status-blue-light-color);
    border-radius: 6px;
}


.charger__panel__status__table__wrap.charging::-webkit-scrollbar-track {
    background: var(--charger-status-charging-light-color);
    border-radius: 6px;
}

.charger__panel__status__table__wrap.idle::-webkit-scrollbar-track {
    background: var(--charger-status-idle-light-color);
    border-radius: 6px;
}

.charger__panel__status__table__wrap.bluecolor::-webkit-scrollbar-thumb {
    background: var(--charger-status-blue-dark-color);
    border-radius: 6px;
    border-top: 150px solid var(--charger-status-blue-light-color);
}


.charger__panel__status__table__wrap.charging::-webkit-scrollbar-thumb {
    background: var(--charger-status-charging-dark-color);
    border-radius: 6px;
    border-top: 150px solid var(--charger-status-charging-light-color);
}

.charger__panel__status__table__wrap.idle::-webkit-scrollbar-thumb {
    background: var(--charger-status-idle-dark-color);
    border-radius: 6px;
    border-top: 150px solid var(--charger-status-idle-light-color);
}


.charger__panel__status__table__wrap::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.charger__panel__status__table__wrap::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 6px;
}


.charger__status__details-bottom {
    display: grid;
    width: 100%;

}

.charger__panel__status__table__wrap.idle {
    background-color: var(--charger-status-idle-table-color);
    border-radius: 0px 8px 8px 8px;
}

.charger__panel__status__table__wrap.charging {
    background-color: var(--charger-status-charging-table-color);
    border-radius: 8px 0px 8px 8px;
}

.charger__panel__status__table__wrap.bluecolor {
    background-color: var(--charger-status-blue-table-color);
    border-radius: 8px 0px 8px 8px;
}

.charger__panel__status__table__row {
    border-radius: 4px;
    background-color: var(--white-color);
    padding: 8px;
    margin-bottom: 8px;
    height: 55px;
}

.charger__panel__status__table__row:last-of-type {
    margin-bottom: 0px;
}

.charger__panel__status__table__header {
    font-size: 10px;
    color: var(--total-alarm-color);
    letter-spacing: -0.01px;
    font-family: var(--font-family-semi-medium);
    font-weight: 400;
    padding-bottom: 4px;
}

.charger__panel__status__table__value {
    font-size: 12px;
    color: var(--primary-text-color);
    letter-spacing: -0.01px;
    font-family: var(--font-family-semi-medium);
    font-weight: 400;
}

.charger__status__history__header__wrap {
    display: flex;
    background-color: var(--primary-white-color);
    padding: 8px 16px;
    flex-direction: column;
}

.charger__status__history__header__wrap .custom__date__picker__wrap {
    background-color: transparent !important;
    display: flex;
    justify-content: flex-end;
}

.charger__status__history__header__wrap-top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px
}

.charger__status__history__header__wrap .custom__date__picker__wrap {
    margin: 8px;
}

.charger__status__history__header-text {
    font-size: 16px;
    font-family: var(--font-family-semi-bold);
    color: var(--primary-text-color);
    letter-spacing: -0.01px;
    font-weight: 500;
    padding-right: 10px;
}

.charger__status__history__header-text.reduced-font-size {
    font-size: 14px;
}

.connector__type__wrap {
    display: flex;
}

.connector__type {
    width: 32px;
    height: 32px;
    color: var(--white-color);
    font-size: 14px;
    font-family: var(--font-family-semi-medium);

    letter-spacing: -0.01px;
    font-weight: 500;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.connector__type-legend {
    width: 10px !important;
    height: 10px !important;
    color: var(--white-color);
    font-size: 7px;
    margin-right: 4px;
}

.connector__type.idle {
    background-color: var(--charger-status-idle-dark-color);
}

.connector__type.bluecolor {
    background-color: var(--charger-status-blue-dark-color);
}

.connector__type.charging {
    background-color: var(--charger-status-charging-dark-color);
}

.connector__type.fourth {
    background-color: var(--charger-status-fourth-dark-color);
}

.charger__status__history__dashboard {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    flex-grow: 1;

    /* width: calc(100% - 346px); */
}

.charger__status__history__header__wrap-left {
    display: flex;
    align-items: center;
}

.charger__status__history__header__wrap-right {
    padding: 0px 8px;
    /* margin-left: auto; */
}

.charger__status__history__header__wrap-right .rms__dropdown__wrap:nth-child(2) {
    min-width: 135px;
    width: 135px;
}

.charger__status__history__header__wrap-right .rms__dropdown__wrap:nth-child(3) {
    min-width: 120px;
    width: 120px;
}

.charger__status__history__header__wrap-right .rms__dropdown__wrap:nth-child(4) {
    min-width: 130px;
    width: 130px;
}

.charger__status__history__header__wrap-right .rms__dropdown__wrap {
    padding-right: 16px;
    min-width: 100px;
}

.charger__status__history__header__wrap-right .rms__dropdown__wrap:last-of-type {
    padding-right: 0px;
    min-width: 150px;
    width: 150px;
}

.charger__status__history__header__wrap-right {
    display: flex;
    align-items: center;
    /* display: grid;
    grid-template-columns: repeat(4, 1fr); */
}

.charger__status__history__header__toggle-option-wrap {
    display: flex;
    align-items: center;
}

.charger__status__history__header__toggle-option {
    all: unset;
    color: var(--primary-text-color);
    font-size: 12px;
    font-family: var(--font-family-regular);

    letter-spacing: -0.01px;
    font-weight: 500;
    padding-right: 16px;
}

.charger__status__history__header__toggle-option.selected {
    color: var(--secondary-blue-color);
}

.charger__status__history__graph__wrap {
    padding: 16px 0px 0px;
}


.charger__panel-refresh-btn-wrap {
    display: flex;
    align-items: center;
}

.charger__panel-bottom-auto-refresh-wrap {
    display: flex;
    padding-bottom: 10px;
    /* padding-right: 16px; */
}

.charger__status__history__logs__container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.charger__status__history__logs__header {
    color: var(--primary-text-color);
    font-size: 16px;
    font-family: var(--font-family-semi-bold);
    letter-spacing: -0.01px;
    font-weight: 500;
}

.charger__status__history__logs__graph {
    display: flex;
}

.charger__status__history__status__logs__wrap {
    display: flex;
    width: 100%;
}

.charger__status__details-bottom-footer {
    color: var(--placeholder-color);
    font-size: 12px;
    font-family: var(--font-family-regular);
    letter-spacing: -0.01px;
    font-weight: 400;
    display: flex;
    align-items: center;
    /* padding-top: 8px; */
    width: 100%;
    justify-content: center;
}

.charger__status__details-bottom-footer .charger__status__details-bottom-footer-skeleton {
    padding: 0px;
    height: 30px;
    width: 100px;
}

.charger__status__details-bottom-footer-skeleton .rectangular__skeleton {
    border-radius: 4px;
}

.charger__status__parameters__container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.charger__status__toggle__wrap {
    background-color: var(--white-color);
    box-shadow: 2px 4px 8px #9C9C9C1A;
    cursor: pointer;
    border: 1px solid var(--grid-layout-border);
    border-radius: 6px;
    display: flex;
    padding: 10px 16px;
    align-items: center;
    justify-content: center;
}

.charger__status__toggle__wrap.expanded {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom: 0px;
}

.charger__status__toggle__wrap-arrow {
    margin-left: auto;
    cursor: pointer;
}

.charger__status__toggle__wrap-icon {
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.charger__status__toggle__wrap-text {
    color: var(--primary-text-color);
    font-size: 16px;
    font-family: var(--font-family-semi-bold);
    letter-spacing: -0.01px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.charger__status__parameters__container {
    padding-bottom: 16px;
}

.charger__panel__status__first__option-header-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: var(--white-color);
    box-shadow: 0px 2px 4px #0000001A;
    column-gap: 10px;
    padding: 8px 20px;
    border: 1px solid var(--primary-white-color);
    border-radius: 4px;
}


.charger__status__toggle__wrap__content {
    box-shadow: 2px 4px 8px #9C9C9C1A;
    border-top: 0px;
    /*overflow-x: auto;*/
    background: var(--primary-white-color);
}

.charger__status__toggle__wrap__content::-webkit-scrollbar {
    display: none;
}

.charger__status__toggle__wrap__content .single__row__render {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
}

.charger__panel__settings-paramters-table .charger__status__toggle__wrap__content .single__row__render {
    grid-template-columns: repeat(auto-fill, minmax(291px, 1fr)) !important;
}

.charger__status__toggle__wrap__content.without__parameters .charger__status__toggle__wrap__content-row {
    flex-direction: column;
}

.charger__status__toggle__wrap__content.with__nested__parameters .charger__status__parameters__container {
    padding-bottom: 0px !important;
}

.charger__status__toggle__wrap__content-row__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.charger__status__toggle__wrap__content-row-value-wrap {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;
    /* white-space: nowrap; */
    overflow-wrap: anywhere;
    color: var(--alarm-summary-header-color);
    font-size: 14px;
    font-weight: 300;
}

.charger__status__toggle__wrap__content-row-parameter-wrap {
    color: var(--alarm-summary-header-color);
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 4px;
    white-space: nowrap;
    display: flex;
}

.charger__status__toggle__wrap__content-row-parameter-wrap-edit-icon {
    padding-left: 6px;
    scale: 0.8
}

.rms__dropdown__wrap.settings_editable_parameters-dropdown {
    width: 100%;
}

.charger__status__toggle__wrap__content-row-value-wrap-unit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 400;
    margin-left: 5px;
    min-width: 14%;
}

.charger__status__toggle__wrap__content-row-value-wrap-submit {
    display: flex;
    align-items: center;
}

.charger__status__toggle__wrap__content-row-value-wrap-submit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
    cursor: pointer;
}

.charger__status__toggle__wrap__content.with__nested__parameters .charger__status__toggle__wrap__content-row {
    border-bottom: 1px solid var(--system-info-border-color);
}

.charger__status__toggle__wrap__content.with__nested__parameters .charger__status__toggle__wrap__content-row.nested {
    border-bottom: 0px;
}



.charger__status__toggle__wrap__content.no__data {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--alarm-summary-header-color);
    font-size: 14px;
    font-weight: 300;
}

.charger__panel__status__first__option-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
}

.charger__panel__status__first__option-border {
    height: 40px;
    width: 1px;
    background-color: var(--grey-card-bg);
}


.charger__panel__status__first__option-header-card-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.charger__panel__status__first__option-header-title {
    font-size: 12px;
    font-family: var(--font-family-semi-medium);
    letter-spacing: -0.01px;
    font-weight: 400;
    padding-right: 16px;
}

.charger__status__toggle__wrap__content-row {
    padding: 16px;
    display: flex;
    border-bottom: 1px solid var(--system-info-border-color);
    align-items: flex-start;
}



.charger__status__toggle__wrap__content-row-value-content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-family: var(--font-family-semi-bold);
    letter-spacing: -0.01px;
    font-weight: 300;
    color: var(--alarm-summary-header-color);
}

.charger__status__toggle__wrap__content-row-value-header {
    font-size: 12px;
    font-family: var(--font-family-semi-bold);
    letter-spacing: -0.01px;
    font-weight: 500;
    color: var(--alarm-summary-header-color);

}

.charger__status__toggle__wrap__content-row-value-text {
    font-size: 14px;
    font-family: var(--font-family-semi-bold);
    letter-spacing: -0.01px;
    font-weight: 500;
    color: var(--primary-text-color);
}

.charger__status__history__graph__wrap .highcharts-legend-item-hidden {
    text-decoration: none !important;
    opacity: 0.5 !important;
}

.charger__panel__status__first__option-header-value {
    font-size: 12px;
    font-family: var(--font-family-regular);
    letter-spacing: -0.01px;
    font-weight: 300;
    color: var(--primary-text-color);
}

.charger__panel__status__first__option-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
}

.charger__panel__status__first__option-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.charger__panel__status__first__option-connector-one-img {
    display: flex;
    flex-direction: column;
}

.charger__panel__status__first__option-details {
    box-shadow: 0px 2px 4px #00000029;
    border: 1px solid var(--primary-white-color);
    width: 282px;
    height: 216px;
    padding: 16px;
}

.charger__panel__status__first__option-title {
    font-size: 12px;
    font-family: var(--font-family-semi-medium);
    letter-spacing: -0.01px;
    font-weight: 400;
    color: var(--primary-text-color);
}

.charger__panel__status__first__option-value {
    font-size: 12px;
    font-family: var(--font-family-regular);
    letter-spacing: -0.01px;
    font-weight: 300;
    color: var(--primary-text-color);
}

.charger__panel__status__first__option-row-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
}

.charger__panel__status__first__option-last-refresh-time {
    font-size: 11px;
    font-family: var(--font-family-regular);
    letter-spacing: -0.01px;
    font-weight: 400;
    color: var(--text-grey-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.charger__panel__status__first__option-header {
    font-size: 17px;
    font-family: var(--font-family-semi-medium);
    letter-spacing: -0.01px;
    font-weight: 500;
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
}

.charger__panel__status-connector {
    width: 40px;
    height: 53px;
}

.charger__panel__status__first__option-connector-one-img-wrap {
    padding: 16px;
    height: 206px;
}

.charger__panel__status__first__option-connector-two-img-wrap {
    padding: 16px;
    height: 206px;
}

.charger_stat-type-break {
    height: 30px;
    width: 1px;
    background-color: var(--text-grey-color);
    margin: 0px 16px;
}

.charger__status__history__header__wrap-right .rms__dropdown__wrap:nth-child(3) {
    padding-right: 0px;
}

.charger__status__toggle__wrap__content-row__nested__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

}

.charger__status__toggle__wrap__content-row.nested {
    display: flex;
    flex-direction: column;
}

.charger__status__history__header__wrap-left .rms__dropdown__wrap {
    width: 180px;
}

.charger__status__history__header__wrap-left .charger__connector__type__dropdown {
    margin-left: 16px;
    width: 130px;
}

.charger__status__history__header__wrap .history__graph__info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
}