.custom__tooltip__label__wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;


}

.label__no__text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.custom__tooltip__label__inside__wrap {
  max-height: 200px;
  overflow: auto;
  display: flex;
  word-break: break-all;
}

.custom__tooltip__label__wrap .text__label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}