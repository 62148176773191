.rms__custom__upload__field__container{
    width: 100%;
    font-size: 13px;
    background: var(--white-color);
    border: 1px solid #B2BCBD;
    border-radius: 4px;
    height: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.rms__drag_drop__icon{
    background: var(--sprite-sheet-url) no-repeat -824px -222px;
    width: 20px;
    height: 16px;
}

.rms__upload__icon{
    background: var(--sprite-sheet-url) no-repeat -858px -223px;
    width: 20px;
    height: 16px;
}

.rms__upload__left__wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rms__upload__text{
    color: var(--placeholder-color);
    font-size: 13px;
    font-family: var(--font-family-regular);
    text-align: left;
    letter-spacing: 0.2px;
    margin-left: 3px;
    padding-left: 10px;
    text-overflow: ellipsis;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
}

.rms__drag_drop__text{
    color: #8C8C8C;
    font-size: 12px;
    font-family: var(--font-family-regular);
    text-align: center;
    margin-left: 3px;
}

.rms__choose__file__text{
    color: var(--secondary-blue-color);
    font-size: 13px;
    font-family: var(--font-family-regular);
    text-align: left;
    letter-spacing: 0.2px;
}

.rms__drag_drop__wrap{
    display: flex;
    align-items: center;
}

.rms__upload__right__wrap{
    width: 40%;
    display: flex;
    align-items: center; 
    text-align: center;
    justify-content: center;
    background-color: var(--secondary-blue-color);
    height: 100%;
    border-radius: 4px;
    padding: 8px;
}

.rms__custom__upload__field{
    z-index: 999;
    position: absolute;
    cursor: pointer;
}

.rms__upload__wrap{
    width: 100%;
}

.rms__upload__left__wrap #upload_button
{
    background-color: transparent;
    box-shadow: none;
    height: 100%;
}

.rms__custom__upload__field__container.disabled {
    background-color: hsl(0, 0%, 95%);
      color: hsl(0, 0%, 60%);
}