.rule__engine__remote__command__side__popover__wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
}

.rule__engine__remote__command__side__popover__row__label{
    font-size: 12px;
    font-family: var(--font-family-regular);
    color: var(--primary-text-color);
    padding: 0px 0px 5px 2px;
}

.rule__engine__remote__command__url__wrapper{
    width: 100%;
    padding-top: 10px;
}