.rule__engine__if__then__else__node {
    display: flex;
    align-items: baseline;
}

.side__popover__container-paper.rule__engine__if__then__else {
    width: 720px;
}

.side__filter__wrap-header.rule__engine__header__config {
    padding: 10px 0px;
}

.transparent-handle {
    background-color: transparent !important;
    /* Makes the handle transparent */
    border: none !important;
    /* Removes the border */
}