.custom__date__picker {
  color: var(--total-alarm-color);
  font-size: 12px;
  letter-spacing: -0.01px;
  font-family: var(--font-family-regular);
  font-weight: 400;
  padding: 4px;
  border-radius: 4px;
  border: 0px;
  width: 100%;
}


.custom__date__picker:focus-visible {
  outline: 0px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 5px !important;

}

.react-datepicker__input-container {
  border: 1px solid;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  padding: 2px 4px;
}


.custom__date__picker__popper {
  z-index: 9999 !important;
  font-family: var(--font-family);
}

.react-datepicker__day {
  color: var(--placeholder-color) !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: var(--border-color) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--secondary-blue-color) !important;
  color: var(--white-color) !important;
}

.react-datepicker__current-month {
  color: var(--secondary-blue-color) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.custom__date__picker__wrap {
  display: flex;
  align-items: center;

  background-color: var(--white-color);

  cursor: pointer;
}

.custom__date__picker__wrap .react-datepicker__input-container:focus {
  border-color: var(--secondary-blue-color);
}


.react-datepicker-wrapper {
  border-radius: 4px;
}

.react-datepicker__time-container ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--secondary-blue-color) !important;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 5%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-dropdown::after {
  content: "\25BC";
  display: block;
  position: relative;
  bottom: 15%;
}

.react-datepicker__close-icon::after {
  background-color: white !important;
  color: var(--text-grey-color) !important;
  font-size: 18px !important;
  font-weight: 500;
  display: flex !important;
  align-items: center !important;
  padding: unset !important;
  margin-right: -5px;
}