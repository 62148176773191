.horizontal__navbar__container {
  border-bottom: 1px solid var(--grey-card-bg);
  display: flex;
  margin-top: 10px;
  flex-grow: 1;
}

.horizontal__nav {
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
  cursor: pointer;
  margin: 0px 10px;
  padding-bottom: 5px;
}

.horizontal__nav:first-of-type {
  margin-left: 0px;
}

.horizontal__nav.selected {
  font-family: var(--font-family-bold);
  border-bottom: 2px solid var(--secondary-blue-color);
  font-weight: 500;
}