.rms__custom__text__area {
    min-width: 100%;
    max-width: 100%;
    font-size: 13px;
    background: var(--white-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    min-height: 100px;
    max-block-size: 200px;
    padding: 8px 0px 0px 8px;
    resize: none;
}

.rms__custom__text__area:focus-visible {
    outline: none;
}

.rms__custom__text__area:disabled {
    background-color: hsl(0, 0%, 95%);
    color: hsl(0, 0%, 60%);
}

.custom-resize {
    resize: auto !important
}