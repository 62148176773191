.auto__refresh__component {
  display: flex;
  border: 1px solid var(--grey-color);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.auto__refresh__component-dropdown-wrap .rms__dropdown__wrap {
  width: 105px;
  display: flex;
  align-items: center;
}


.auto__refresh__component-dropdown-wrap .rms__dropdown__container {
  height: 100%;
  align-items: center;
  display: flex;
}

.auto__refresh__component-dropdown-wrap .rms__dropdown__value-container {
  /* height: 25px !important; */
  padding: 2px !important;
}

.auto__refresh__component-dropdown-wrap .rms__dropdown__indicators {
  min-height: 25px !important;
  width: 25px !important;
}

.auto__refresh__component-dropdown-wrap .rms__dropdown__control {
  min-height: 25px !important;
}

.auto__refresh__component-dropdown-wrap {
  display: flex;
}

.auto__refresh__component-circular-progress-wrap {
  padding: 5px;
  border-right: 1px solid var(--grey-color);
}

.auto__refresh__component-circular-progress-wrap:hover {
  cursor: pointer;
}

.auto-refresh-linear-progress-bar {
  background-color: var(--grey-color) !important;
}

.auto-refresh-linear-progress-bar .MuiLinearProgress-bar {
  background-color: var(--secondary-blue-color) !important;
}

#auto-refresh-dropdown .rms__dropdown__control {
  border-radius: 0px !important;
  border-top-right-radius: 4px !important;
  border: 0px !important;

}

.auto__refresh__component-circular-progress {
  display: flex !important;
}