.app__dashboard__header-container {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px 30px 20px;
  align-items: baseline;
}

.app__dashboard__header {
  color: var(--primary-text-color);
  font-size: 24px;
  font-family: var(--font-family-semi-bold);
  letter-spacing: 0.1px;
  font-weight: 550;
}

.add__charger__wrap-header-text,
.add__parameter__wrap-header-text
{
  padding-top: 15px;
}