.rms__dropdown__multi-value__label {
  background-color: var(--grid-layout-border) !important;
  border-top-left-radius: 23px !important;
  border-bottom-left-radius: 23px !important;
}

.rms__dropdown__multi-value__remove {
  background-color: var(--grid-layout-border) !important;
  border-top-right-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
}

.rms__dropdown__option__wrap {
  display: flex;
  padding: 6px;
  cursor: pointer;
}

.rms__dropdown__multi-value {
  border-radius: 12px !important;
  /* max-width: 70px !important; */
  max-width: 60% !important
}


.rms__dropdown__option-label {
  color: var(--primary-text-color);
  cursor: pointer;
  font-weight: 300;
  display: flex;
  align-items: center;
  padding-left: 4px;
  font-size: 13px !important;
}

.rms__dropdown__option-layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  font-weight: 300;
  padding-left: 4px;
  font-size: 13px !important;
  color: var(--primary-text-color);
}

.rms__dropdown__option-label-left {
  flex: 1;
  text-align: left;
}

.rms__dropdown__option-label-right {
  flex: 1;
  text-align: right;
  padding-right: 10px;
}