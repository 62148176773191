:root {
  --sprite-sheet-url: url('./rmsAssets/rms_icons.svg');
  --primary-white-color: #F8F8F8;
  --secondary-blue-color: #06BFBF;
  --navbar-color: #002126;
  --font-family: Inter;
  --sidebar-bg-color: #FAFAFA;
  --primary-text-color: #002126;
  --table-header-color: #546264;
  --white-color: #FFFFFF;
  --border-color: #00000029;
  --placeholder-color: #8C8C8C;
  --text-grey-color: #bbbbbb;
  --red-color: #BB0808;
  --green-color: #27C380;
  --grey-color: #D9D9D9;
  --sub-header-text-color: #10171F;
  --font-family-regular: Inter, Regular;
  --font-family-semi-bold: Inter, SemiBold;
  --font-family-bold:Inter, Bold;
  --font-family-semi-medium: Inter, Medium;
  --mobile-view-border-color: #D9D9D9;
  --mobile-view-header-text-color: #082025;
  --mobile-view-content-text-color: #888888;
  --tooltip-background-color: #676767;
  --tooltip-text-color:#EDEDED;
  --grid-layout-border: #E5E5E5;
  --bubble-green-bg-color: #E6F9F9;
  --bubble-yellow-bg-color: #F2F9EC;
  --total-alarm-color:#8C8C8C;
  --critical-alarm-color:#BB0808;
  --major-alarm-color:#F0690F;
  --minor-alarm-color:#F0BB0F;
  --alarm-summary-header-color:#565656;
  --card-green-color: #57DD9C;
  --card-yellow-color:#F3CA44;
  --card-grey-color:#CACACA;
  --card-red-color:#CA3E3E;
  --card-blue-color:#3DCDCD;
  --grey-card-bg:#ECECEC;
  --border-color2: #F0F0F0;
  --border-color3: #9BA5A6;
  --no-alarm-color: #7BC045;
  --btn-nav-color: #FBFBFB;
  --btn-nav-hover-color: #e5e5e5;
  --skeleton-bg-color: #F2F2F2;
  --skeleton-card-color: #CCCCCC;
  --charger-status-idle-light-color:#F3EEF4;
  --charger-status-idle-dark-color:#7B5D8B;
  --charger-status-idle-table-color: #E5DFE9;

  --charger-status-charging-dark-color:#564B9C;
  --charger-status-charging-light-color: #D9D2FF;
  --charger-status-charging-table-color:#F3F1FE;

  --charger-status-blue-light-color: #B3DEFF;
  --charger-status-blue-table-color:#E8F0F6;
  --charger-status-blue-dark-color:#1465A2;
  
  --session-log-charging-color:#61ce9e;
  --session-log-pluggedIn-color:#564b9c;;
  --session-log-pluggedOut-color:#d42054;

  --system-info-border-color: #E5E5E5CC;
  --charger-card-pink-color: #D0A2F7;
  --charger-card-purple-color: #F4C2C2;
  --enable-toggle-text-color: #1E8700;
  --disable-toggle-text-color: #CC0000;


  --new-tooltip-bg-color: #FFFFFF;
  --new-tooltip-text-color: #475569;
  --cms-connectivity-fail-color:#F0BB0F;
  --charger-status-fourth-dark-color:#2CAFFE;

  
 
}

* {
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  box-sizing: border-box;
}


/* Scroll bar changes... */


::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Placeholder text color changes... */
::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
  /* Firefox */
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.01px;
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: var(--placeholder-color);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.01px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--primary-text-color);
    transition: background-color 5000s ease-in-out 0s;
}

.tooltip_container{
  padding: 0px 10px;
}