.side__popover__container-paper {
  width: 511px;
  background-color: var(--white-color);
  border-radius: 0px !important;
  margin-top: 50px !important;
  height: 100vh;
  max-height: 100dvh !important;
}

.side__filter__wrap-header-text {
  font-size: 16px;
  font-family: var(--font-family-bold);
  color: var(--primary-text-color);
  font-weight: 700;
}

.side__filter__wrap-header-secondary-text {
  font-size: 16px;
  font-family: var(--font-family-bold);
  color: var(--primary-text-color);
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding-bottom: 8px; */
}

.side__filter__wrap-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding-bottom: 32px;
  background-color: white;
  z-index: 12;
  padding-top: 15px;
}

.side__filter__wrap {
  overflow-y: auto;  
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.side__filter__wrap-footer {
position: sticky;
margin-top: auto;
/* bottom: 50px; */
background-color: white;
z-index: 12;
padding: 20px 0px;
height: 150px;
}

.side__filter__wrap-content{
 display: flex;
 flex-direction: column;
 padding: 0px 1px;
 overflow-y: auto;
 height: 100%
}

.side__filter__wrap-footer .primary__btn {
  padding: 8px 16px !important;
  font-size: 14px !important;
}

.side__filter__wrap-footer .secondary__btn {
  padding: 8px 16px !important;
  font-size: 14px !important;
}


.side__filter__wrap-content-row-label {
  font-size: 12px;
  font-family: var(--font-family-regular);
  color: var(--primary-text-color);
  padding: 0px 0px 5px 2px;
}

.side__filter__wrap-header.rule__engine__header__config {
  padding: 10px 0px;
}

.secondary__text__header__wrapper{
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  padding-bottom: 8px;
}

.side__popover__icon__heading__wrapper{
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.side__filter__wrap-icon{
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.action__control__side__popover__icon{
  display: flex;
}
