.alarms__dashboard__container {
  padding: 16px 20px 0px 20px;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.alarms__dashboard__container .alarm__card__skeleton {
  padding: 0px;
  height: 50px;
}

.alarms__dashboard__container .table__skeleton__container {
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.charger__panel__alarm__history__table__wrap {
  overflow: hidden;
}

.charger__panel__alarm__history__dashboard {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.alarms__dashboard__container-sub-header {
  display: flex;
  /* padding-top: 15px; */
}

.alarms__dashboard__container-header-right {
  display: flex;
  margin-left: auto;
  align-items: flex-start;
}


.alarms__dashboard__container-sub-header-left .horizontal__nav {
  font-size: 16px;
}

.alarms__dashboard__container-sub-header-left .horizontal__nav.selected {
  font-weight: 600;
}

.alarms__dashboard__container-sub-header .horizontal__navbar__container {
  border-bottom: 0px;
}

.alarms__dashboard__table__container-header-wrap {
  display: flex;
}

.alarms__dashboard__table__container-header-wrap-right {
  margin-left: auto;
}

.alarms__dashboard__container-cards-wrap {
  display: grid;
  padding: 20px 0px 10px;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
}


.active__alarms__severity__cell__wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.active__alarms__warranty__cell__wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alarm__card__notes__container {
    background-color: var(--primary-white-color);
    border-radius: 8px;
    display: flex;
    box-shadow: 2px 4px 8px #9c9c9c1a;
    border: 1px solid var(--grey-color);
    margin: 10px 0px 0px;
    flex-direction: column;
    width: 100%;
}

.alarm__row__card__container {
  background-color: var(--primary-white-color);
  border-radius: 8px;
  display: flex;
  box-shadow: 2px 4px 8px #9c9c9c1a;
  border: 1px solid var(--grey-color);
  margin: 10px 0px;
  flex-direction: column;
  width: 100%;
}

.alarm__row__card__container-top {
  display: flex;
  background-color: var(--white-color);
  border: 1px solid var(--grey-color);
  border-radius: 8px;
  margin: 10px;
  border-left: 0px;
}

.alarm__row__card__container-bottom {
  display: flex;
}

.alarm__row__card__container-top-border {
  width: 4px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.alarm__row__card__container-bottom-card {
  width: 20%;
  margin: 10px;
  border-right: 1px solid var(--grey-color);
}

.alarm__row__card__container-bottom-card:last-of-type {
  border-right: 0px;
}

.alarm__row__card__container-bottom-card-header {
  color: var(--primary-text-color);
  font-size: 12px;
  font-family: var(--font-family-regular) !important;
  letter-spacing: 0.2px;
  font-weight: 400;
}

.alarm__row__card__container-bottom-card-content {
  line-height: 2;
  font-size: 14px;
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
  font-family: var(--font-family-semi-bold) !important;
  font-weight: 500;
}

.alarm__row__card__container-top-card {
  padding: 10px;
  width: 24%;
}

.alarm__row__card__container-top-card-header {
  display: flex;
  justify-content: flex-start;
}

.alarm__row__card__container-top-card-content {
  justify-content: flex-start;
}

.alarm__row__card__container-top-card-header span {
  font-size: 12px;
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
  font-family: var(--font-family-regular) !important;
}

.alarm__row__card__container-top-card-severity-status-text {
  text-transform: capitalize;
  font-size: 10px !important;
  color: var(--white-color) !important;
  letter-spacing: -0.01px;
  font-family: var(--font-family-semi-bold) !important;
  padding: 2px;
  border-radius: 4px;
  margin-left: 5px;
}

.alarm__row__card__container-top-card-content {
  font-size: 14px;
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
  font-family: var(--font-family-semi-bold) !important;
  font-weight: 500;
}

.alarm__row__card__container-top-card.arrow-wrap {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0px;
  width: 4%;
  cursor: pointer;
}

.alarm__row__card__container-top-card.arrow-wrap .rms__right-black-arrow {
  scale: 1.2;
}

.alarm__row__card__container-top-card-content {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.alarm__row__card__container-top-card-content .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alarm__row__card__container-top-card-content .text.Alarm {
  padding-left: 5px;
  padding-top: 5px;
}

.active__alarms__name__wrap .add__station__back-btn_wrap .link__btn-text {
  font-weight: 500;
}

.alarm_name-text {
  font-family: var(--font-family);
  color: var(--secondary-blue-color);
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.alarm__filter__layout__wrap .custom__date__picker__wrap{
  width: 100%;
}

.alarm__filter__layout__wrap  .react-datepicker-wrapper{
  width: 100%;
}

.alarms__dashboard__container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alarms__dashboard__container-header-filter-btn-wrap {
  display: grid;
  /* grid-template-columns: repeat(5, minmax(150px,1fr)); */
  grid-template-columns: 150px 130px 150px 100px 180px 150px;
  gap: 10px;
  align-items: flex-end;
}

.alarms__dashboard__container-header-filter-btn-wrap.history {
  grid-template-columns: 145px 130px 150px 180px 145px 145px;
}

.alarms__dashboard__container-header-filter-btn-wrap .custom__date__picker__popper {
  left: -30px !important;
}

.alarms__dashboard__container-header-right-export {
  display: flex;
  align-items: center;
  margin-right: 10px;
  gap: 10px;
}

.alarms__dashboard__container-header-right-export .export__csv__btn {
  height:32px;
}
.manage__alarms__side__filters .side__filter__wrap-content .react-datepicker-wrapper{
  width: 100%;
}
.manage__alarms__side__filters .side__filter__wrap-content-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}