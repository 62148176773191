.org__form__wrap {
    padding: 16px 20px;
    min-height: calc(100dvh - 48px);
    height: calc(100dvh - 48px);
    display: flex;
    flex-direction: column;
  }
  
  .org__form__wrap-header {
    display: flex;
    align-items: center;
    padding-top: 15px;
  }
  
  .org__form__wrap-content {
    margin: 10px 0px;
    height: 100%;
    overflow-y: auto;
  }
  
  .org__form__wrap-content .custom__form__wrap {
    padding: unset;
    min-height: auto;
    height: auto;
  }
  
  .org__form__wrap-footer {
    margin-top: auto;
    position: relative; 
    width: 100%;
    padding-top: 10px;
  }
  
  .org__form__back__btn {
    width: fit-content;
  }

  .org__form_header-edit {
    padding: 5px !important;
  }