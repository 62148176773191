.rule__engine__send__notification__side__popover__wrapper{
    padding: 18px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rule__engine__send__notification__side__popover__row__label{
    font-size: 12px;
    font-family: var(--font-family-regular);
    color: var(--primary-text-color);
    padding: 0px 0px 5px 2px;
}

.rule__engine__send__notification__popover__footer{
    margin-top: auto;
    padding-bottom: 32px;
}