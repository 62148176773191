.heading-note{
    font-size: 14px;
    font-style: italic;
    padding: 10px 0px;
    font-weight: 300;
  }

.screen{
    width: 25%;
    background-color: #F8F8F8;
    padding: 10px 10px;
    font-weight: 600;
    font-size: 14px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    display: flex;
    align-content: center;
}

.sub-screen{
    width: 25%;
    background-color: #F8F8F8;
    padding: 10px 0px 10px 25px;
    font-size: 14px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
}

.screen.sub-screen-present{
    width: 25%;
    background-color: white;
    padding: 10px 10px;
    font-weight: 600;
    font-size: 14px;
    border-top: none;
    border-bottom: none;
    border-right: none;
    display: flex;
}

.screen-operations{
    margin-bottom: 15px;
    display: flex;
}

.sub-screen-operations{
    display: flex;
}

.screen-operations.sub-screen-present{
    display: block;
}

.checkbox-text{
    margin: 2px 0px 0px 10px;
    font-size: 14px;
}

.operation {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 75%;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.sub-operation {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 75%;
    border-bottom: 1px solid #D9D9D9;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.operation-button {
    height: 25px;
    background-color: #F8F8F8;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 4px; 
    color: #BBBBBB;
    font-size: 14px;
    margin: 5px 15px;
    padding: 4px 7px 4px 8px;
  }
  
  .operation-button.selected {
    background-color: #06BFBF;
    color: white;
  }