.summary_page_wrap_content {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.summary__section__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.summary__section__content_header {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    font-family: var(--font-family-regular);
    color: var(--primary-text-color);
}

.summary__section__content_row {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

.summary__section__content__row__single {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 30%;
    font-size: 14px;
}

.summary-page-label {
    font-size: 12px !important;
    display: flex;
    margin: 6px 0px !important;
    color: #676767 !important;
    line-height: 14.52px;
    letter-spacing: -0.01px !important;
    font-family: var(--font-family-regular) !important;
}

.custom__tooltip__label__wrap .summary_section_label_value {
    font-weight: 400;
    size: 14px;
    line-height: 16.94px;
    color: var(--primary-text-color);
    width: 100%;
  }

.summary__section__content__row__single .custom__tooltip__label__wrap .text__label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-text-color);
  }

.custom-hr{
    border-color: #D9D9D9;
    border-width: 0 0 1px 0;
    height: 1px;
}