.custom__form__wrap.add__parameter__wrap {
    height: auto !important;
    min-height: max-content;
  }
  
  .add__parameter__dashboard {
    padding: 16px 0px;
    overflow-y: auto;
  }
  
  .add__parameter__btns {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }
  
  .add__parameter__parameters__list {
    overflow-y: auto;
    max-height: 48vh;
    margin-top: 20px;
  }
  
  
  .add__parameter__single__parameters__container {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding-bottom: .5px;
    margin-top: 5px;
    padding-right: 9px;
  }
  
  .add__parameter__toggle__wrap {
    background-color: var(--white-color);
    box-shadow: 2px 4px 8px #9C9C9C1A;
    cursor: pointer;
    border: 1px solid var(--grid-layout-border);
    border-radius: 6px;
    display: flex;
    padding: 10px 16px;
    align-items: center;
    justify-content: center;
    height: 60px;
  }
  
  .add__parameter__toggle__wrap-text {
    color: var(--primary-text-color);
    font-size: 16px;
    font-family: var(--font-family-semi-bold);
    letter-spacing: -0.01px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  
  .add__parameter__toggle__wrap-arrow {
    margin-left: auto;
    cursor: pointer;
  }
  
  .add__parameter__toggle__wrap__content {
    box-shadow: 2px 4px 8px #9C9C9C1A;
    border-top: 0px;
    background: var(--primary-white-color);
  }
  
  .add__parameter__toggle__wrap__content-row__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .add__parameter__toggle__wrap__content-row-parameter-wrap {
    color: black;
    font-size: 12px;
    padding-bottom: 1vh;
    white-space: nowrap;
    display: flex;
  }
  
  .add__parameter__toggle__wrap__content-row-value-wrap {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;
  
    overflow-wrap: anywhere;
    color: var(--alarm-summary-header-color);
    font-size: 14px;
    font-weight: 300;
  }
  
  
  .add__parameter__single__parameters__container .add-parameter-dropdown-options {
    width: 27%;
  }
  
  
  /* css of date and time  */
  
  .custom__date__picker__wrap__add__parameter {
    margin-bottom: 1vh;
  }
  
  .custom-datepicker {
    border: 0px solid black;
    border-radius: 4px;
    outline: none;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
  }
  
  
  .add__parameter__single__parameters__container .rms__custom__input__field {
    width: 27%;
  }
  
  .add__parameter__btns .send-now-and-later-box {
    display: flex;
    flex-direction: row;
    padding-bottom: 1vh;
  }
  
  .add__parameter__btns .add-parameter-scheduled-date {
    padding-top: 1px;
  }
  
  .custom__form__wrap.add__parameter__wrap .rms__dropdown__multi-value {
    border-radius: 12px !important;
    max-width: fit-content !important;
  }
  
  .manage__update_commands__table .rms__table__dashboard__search__wrap-right {
    grid-template-columns: repeat(3, minmax(225px, 1fr));
  }
  
  .remote-command-content-wrap-top {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  
  .add-parameter-content-wrap {
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
    height: 100%;
  }
  
  .add__paramters__paramters-table .charger__status__toggle__wrap__content .single__row__render {
    grid-template-columns: repeat(auto-fill, minmax(295px, 1fr)) !important;
  }

  .add-remote-command-heading{
    border-bottom: 2px var(--secondary-blue-color) solid;
    width: 140px;
  }
  .remote-commands-ftp-software-upgrade-heading-container{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .update_remote_command_charger-selection .custom__radio__form-search-box-container .search__wrap-right {
    display: grid;
    grid-template-columns: repeat(4, minmax(150px, 1fr));
    column-gap: 10px;
  }
  