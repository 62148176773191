.custom__stepper__form__wrap {
  padding: 16px 20px;
  min-height: calc(100dvh - 48px);
  height: calc(100dvh - 48px);
  display: flex;
  flex-direction: column;
}

.custom__stepper__form__wrap-header {
  padding-top: 15px;
}

.custom__stepper__form__wrap-stepper {
  padding-top: 25px;
}

.custom__stepper__form__wrap-content {
  overflow-y: auto;
  height: 100%;
}

.custom__stepper__form__wrap-content .custom__form__wrap {
  padding: unset;
  min-height: auto;
  height: auto;
}

.custom__stepper__form__wrap-footer {
  margin-top: auto;
  position: relative; 
  width: 100%;
  padding-top: 10px;
}

.add__user__back__btn,
.add__role__back__btn {
  width: fit-content;
}

.custom__stepper__form__wrap-content .custom__preview__form{
  padding: unset;
  min-height: auto;
  height: 100%;
}