/*Individual Alarm Style Starts...... */

.alarm__panel {
    width: 100%;
    padding: 16px 20px 0px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
 
.back__btn-container {
    width: max-content;
}

.alarm__panel-header {
    display: flex;
    padding-top: 15px;
    justify-content: space-between;
    align-items: center;
  }
  
  .alarm__panel-sub-header-wrap {
    display: flex;
  }
  
  .alarm__panel-header-content-wrap {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .alarm__panel-detail-wrapper {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .alarm__panel__core__dashboard {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primary-white-color);
    border-radius: 4px;
    box-shadow: 0px 2px 4px #0000001A;
    padding: 16px;
    height: 100%;
  }

  .alarm__panel__sub_tab_dashboard-header {
    display: flex;
    flex-wrap: wrap;
    background: #F8F8F8 0% 0% no-repeat padding-box;
  }

  .alarm__panel__sub_tab_dashboard__nav__btn-wrapper.selected .alarm__panel__sub_tab_dashboard__nav__btn {
    background-color: var(--grey-color) !important;
    font-weight: 600;
  }
  
  .alarm__panel__sub_tab_dashboard__nav__btn {
    background-color: var(--btn-nav-color) !important;
  }
  
  .alarm__panel__sub_tab_dashboard__nav__btn:hover {
    background-color: var(--btn-nav-hover-color) !important;
  
  }

  .alarm__core__dashboard-radio-buttons-wrap {
    border: 1px solid var(--border-color2);
    border-radius: 4px;
    padding: 10px 15px;
  }

  .alarm__core__dashboard-ratdio-buttons-description-text {
    font-size: 15px;
  }

  .alarm__core__dashboard-ratdio-buttons-description {
    display: flex;
    align-items: center;
    padding: 16px 0px;
  }

  .alarm__core_dashboard-content {
    padding-top: 10px;
    padding-right: 50px;
  }

  .alarm__core_dashboard-footer-wrap {
    margin-top: auto;
  }

  .alarm__panel__remote_trigger__dashboard {
    overflow: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primary-white-color);
    border-radius: 4px;
    box-shadow: 0px 2px 4px #0000001A;
    padding: 16px;
    height: 472px;
  }

  .alarm__remote_trigger__dashboard-ratdio-buttons-description-text {
    font-size: 15px;
  }

  .alarm__remote_trigger__dashboard-ratdio-buttons-description {
    display: flex;
    align-items: center;
    padding: 16px 0px;
  }

  .alarm__remote_trigger_dashboard-content {
    padding-top: 10px;
    padding-right: 50px;
  }

  .alarm__remote_trigger__dashboard-radio-buttons-wrapper{
    display: flex;
  }
  .alarm__remote_trigger-input{
    width: 240px;
  }

  .alarm__remote_trigger_dashboard-footer-wrap {
    margin-top: auto;
  }

  .alarm__remote_trigger_radio-text {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-family: var(--font-family-semi-bold);
    color: var(--primary-text-color);
    letter-spacing: -0.01px;
    font-weight: 600;
    padding-right: 16px;
}

.alarm__remote_trigger__dashboard-radio-buttons-wrap {
  border: 1px solid var(--border-color2);
  border-radius: 4px;
  padding: 10px 15px;
}

.alarm__panel__firmware_management__dashboard {
  display: flex;
  overflow: auto;
  flex-direction: column;
  border: 1px solid var(--primary-white-color);
  border-radius: 4px;
  box-shadow: 0px 2px 4px #0000001A;
  padding: 16px;
  height: 472px;
}

.alarm__firmware_management__dashboard-ratdio-buttons-description-text {
  font-size: 15px;
}

.alarm__firmware_management__dashboard-ratdio-buttons-description {
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.alarm__firmware_management_dashboard-content {
  padding-top: 10px;
  padding-right: 50px;
}

.alarm__firmware_management__dashboard-radio-buttons-wrapper{
  display: flex;
}
.alarm__firmware_management-input{
  width: 240px;
}

.alarm__firmware_management_dashboard-footer-wrap {
  margin-top: auto;
}

.alarm__firmware_management_radio-text {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-family: var(--font-family-semi-bold);
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
  font-weight: 600;
  padding-right: 16px;
}

.alarm__firmware_management__dashboard-radio-buttons-wrap {
border: 1px solid var(--border-color2);
border-radius: 4px;
padding: 10px 15px;
}

.alarm__panel__reservation__dashboard {
  display: flex;
  overflow: auto;
  flex-direction: column;
  border: 1px solid var(--primary-white-color);
  border-radius: 4px;
  box-shadow: 0px 2px 4px #0000001A;
  padding: 16px;
  height: 472px;
}

.alarm__reservation__dashboard-ratdio-buttons-description-text {
  font-size: 15px;
}

.alarm__reservation__dashboard-ratdio-buttons-description {
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.alarm__reservation_dashboard-content {
  padding-top: 10px;
  padding-right: 50px;
}

.alarm__reservation__dashboard-radio-buttons-wrapper{
  display: flex;
}
.alarm__reservation-input{
  width: 240px;
}

.alarm__reservation_dashboard-footer-wrap {
  margin-top: auto;
}

.alarm__reservation_radio-text {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-family: var(--font-family-semi-bold);
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
  font-weight: 600;
  padding-right: 16px;
}

.alarm__reservation__dashboard-radio-buttons-wrap {
border: 1px solid var(--border-color2);
border-radius: 4px;
padding: 10px 15px;
}

.alarm__panel__local_auth_list_management__dashboard {
  display: flex;
  overflow: auto;
  flex-direction: column;
  border: 1px solid var(--primary-white-color);
  border-radius: 4px;
  box-shadow: 0px 2px 4px #0000001A;
  padding: 16px;
  height: 472px;
}

.alarm__local_auth_list_management__dashboard-ratdio-buttons-description-text {
  font-size: 15px;
}

.alarm__local_auth_list_management__dashboard-ratdio-buttons-description {
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.alarm__local_auth_list_management_dashboard-content {
  padding-top: 10px;
  padding-right: 50px;
}

.alarm__local_auth_list_management__dashboard-radio-buttons-wrapper{
  display: flex;
}
.alarm__local_auth_list_management-input{
  width: 240px;
}

.alarm__local_auth_list_management_dashboard-footer-wrap {
  margin-top: auto;
}

.alarm__local_auth_list_management_radio-text {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-family: var(--font-family-semi-bold);
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
  font-weight: 600;
  padding-right: 16px;
}

.alarm__local_auth_list_management__dashboard-radio-buttons-wrap {
border: 1px solid var(--border-color2);
border-radius: 4px;
padding: 10px 15px;
}

.alarm__card__notes {
  width: 100%;
  padding: 10px 0px 0px;
  font-size: 14px;
  font-family: var(--font-family-bold);
  color: var(--primary-text-color);
  font-weight: 500;
}

.alarm__card__notes-button {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-top: 10px;
}

.alarm__card__notes-button .primary__btn {
  margin-right: unset !important;
}