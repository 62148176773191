/* Table Style Starts...... */
.manage__users__table .rms__table__dashboard__search__wrap-right {
  grid-template-columns: repeat(1,minmax(200px,1fr));
}

.users__action__icons__wrap .action__icon__btn,
.org__action__icons__wrap .action__icon__btn,
.role__action__icons__wrap .action__icon__btn {
  margin-left: 10px !important;
  margin-right: 0px !important;
}

.individual__user__text,
.individual__org__text,
.individual__role__text {
  color: #3dcdcd;
  font-size: 12px;
  font-family: var(--font-family-regular);
  letter-spacing: -0.01px;
  font-weight: 500;
  cursor: pointer;
}

.custom__stepper__form__prev__button {
  margin-right: 10px !important;
}

.manage__roles__table .MuiDataGrid-cell {
  padding: 5px 10px !important;
  min-height: auto !important;
  max-height: none !important;
  overflow: unset !important;
  white-space:unset !important;
}

.manage__roles__table .MuiDataGrid-row {
  max-height: none !important;
  min-height: auto !important;
}