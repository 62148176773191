.rule_engine_grid__container_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.rule_engine_grid__container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 56px);
}

.rule_engine_card__container {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Default shadow */
  border-radius: 8px;
  /* Smooth edges for better visuals */
  overflow: hidden;
  /* To prevent content overflow */
}

.rule_engine_card__container:hover {
  transform: translateY(-10px);
  /* Lift the card slightly */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  /* Subtle shadow on hover */
  cursor: pointer;
  /* Change cursor to indicate interactivity */
}

.rule_engine_all_rules_list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

/* .rule_engine_all_rules_list.configure_notification_card {
  height: 68.5vh !important;
} */

.rule_engine_grid_view_total_count {
  font-size: 14px;
}

.grid_card_footer_pagination_container {
  display: flex;
  align-items: center;
  position: sticky;
  min-height: 47px;
}

.rule_engine_card__container {
  max-width: 265px;
  min-width: 265px;
  height: 140px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: '#ffffff';
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  gap: 18px
}

.rule_engine_card__container.rule_engine_card {
  max-width: 265px;
  min-width: 265px;
  height: 140px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: '#ffffff';
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  gap: 15px
}

.rule_engine_card_alarm_type_container {

  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  padding-top: 10px;
  color: #696969;
}

.rule_engine_single_card {
  padding: 16px 11px;
}

.rule_engine_card_header {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.rule_engine_card_body {
  font-size: 12px;
  font-weight: 400px;
  color: #565656;
  height: 28px;

}

.rule_engine_card_body {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.rule_engine_card_date_container {
  font-size: 12px;
  font-weight: 400px !important;
  color: #696969;
}


.rule_engine_card_rule_name_container {
  flex-grow: 1;
  margin-left: 2px;
  width: 65%;

}

.rule_engine_card_rule_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.rule_engine_card_status_delete_container {
  display: flex;
  align-items: center;
}

.rule_engine_card_footer_display_action_buttons {
  display: flex;
  overflow-x: auto;
}

.rule_engine_card_footer_display_notification {
  font-size: 14px;
  display: flex;
  font-weight: 400px;
  color: #696969;
  gap: 8px;
  overflow-x: auto;
}

.rule_engine_card_actionButton {
  width: auto;
  height: 22px;
  padding: 2px 2px;
  background-color: #f0f0f0;
  font-size: 11px;
  font-weight: 400;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  white-space: nowrap;
  color: #8C8C8C;
  ;
}

.rule_engine_draft_rules_list {
  display: flex;
  overflow-x: auto;
}

.rule_engine_card_draft_scroll_controls {
  display: flex;
  align-items: center;
  position: relative;
}

.rule_engine_card_draft_scroll_arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rule_engine_card_draft_scroll_arrow:hover {
  background-color: #f0f0f0;
}


.rule_engine_card_draft_left_arrow {
  left: 0px;
}

.rule_engine_card_draft_right_arrow {
  right: 0px;
}

.grid_card_pagination_footer {
  display: flex;
  padding: 12px;
  height: 56px;
  position: sticky;
  bottom: 0;
}