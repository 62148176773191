.timeline__quick__filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    font-size: 12px;
    font-family: var(--font-family-regular);
}

.timeline__quick__filter__item {
    display: flex;
    gap: 4px;
}

.notification__history__popover {
    display: flex;
    flex-direction: column;
}

.timeline__quick__filter .react-datepicker__input-container {
    padding: 2px !important;
    border: none !important;
}

.timeline__quick__filter .react-datepicker__close-icon {
    display: none;
}

/* Tempkate Styling ------------------------------------------- */
.notification__template__recepeints {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-weight: 400;
}

.notification__template__recepeints__list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    row-gap: 12px;
}

.notification__template__recepeints__list__item {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    padding: 2px;
    border: 1px solid var(--border-color);
    background-color: var(--skeleton-bg-color);
}