.rms__icon {
  background: var(--sprite-sheet-url);
  background-repeat: no-repeat;
}

.navbar__expand__collapse__icon {
  background-position: -840px -83px;
  width: 16px;
  height: 12px;
}

.navbar__close__icon {
  background-position: -602px -271px;
  width: 12px;
  height: 12px;
}

.navbar__logo__icon {
  background-position: -61px -223px;
  width: 102px;
  height: 27px;
  scale: 0.8;
}


.navbar__settings__icon {
  background-position: -1064px -79px;
  width: 21px;
  height: 21px;
}

.navbar__profile__icon {
  background-position: -1280px -77px;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;
}

.navbar__avatar__icon {
  background-position: -209px -227px;
  width: 18px;
  height: 18px;
}


.navbar__logout__icon {
  background-position: -244px -229px;
  width: 16px;
  height: 14px;
}

.rms__sidebar__item:hover .user__management__icon {
  background-position: -2530px -170px;
  width: 16px;
  height: 15px;
}


.app__sidebar .reports__icon {
  background-position: -546px -80px;


  width: 18px;
  height: 19px;
}

.rms__sidebar__item:hover .reports__icon {
  background-position: -2572px -164px;
  width: 21px;
  height: 18px;
}

.rms__sidebar__item.selected .reports__icon {
  background-position: -2572px -164px;
  width: 21px;
  height: 18px;
}



.app__sidebar .dashboard__icon {
  background-position: -896px -80px;
  width: 18px;
  height: 19px;
}

.rms__sidebar__item:hover .dashboard__icon {
  background-position: -2854px -68px;
  width: 18px;
  height: 18px;
}

.rms__sidebar__item.selected .dashboard__icon {
  background-position: -2854px -68px;
  width: 18px;
  height: 18px;
}

.app__sidebar .charging__stations__icon {
  background-position: -2897px -66px;
  width: 21px;
  height: 21px;
}

.rms__sidebar__item:hover .charging__stations__icon {
  background-position: -786px -165px;
  width: 20px;
  height: 20px;
}

.rms__sidebar__item.selected .charging__stations__icon {
  background-position: -786px -165px;
  width: 20px;
  height: 20px;
}

.chargers__icon {
  background-position: -2809px -66px;
  width: 20px;
  height: 20px;
}

.rms__sidebar__item:hover .chargers__icon {
  background-position: -1650px -80px;
  width: 19px;
  height: 19px;
}

.rms__sidebar__item.selected .chargers__icon {
  background-position: -1650px -80px;
  width: 19px;
  height: 19px;
}

.alarm__management__icon {
  background-position: -2716px -68px;
  width: 20px;
  height: 18px;
}

.rms__sidebar__item:hover .alarm__management__icon {
  background-position: -2706px -164px;
  width: 19px;
  height: 17px;
}

.rms__sidebar__item.selected .alarm__management__icon {
  background-position: -2706px -164px;
  width: 19px;
  height: 17px;
}

.customer__management__icon {
  background-position: -661px -80px;
  width: 16px;
  height: 20px;
}

.configure__notification__icon {
  background-position: -2671px -101px;
  width: 21px;
  height: 21px;
}

.rms__sidebar__item:hover .configure__notification__icon {
  background-position: -2714px -102px;
  width: 21px;
  height: 21px;

}

.rms__sidebar__item.selected .configure__notification__icon {
  background-position: -2714px -102px;
  width: 21px;
  height: 21px;
}

.rule__engine__icon {
  background-position: -2746px -102px;
  width: 28px;
  height: 20px;
}

.rms__sidebar__item:hover .rule__engine__icon {
  background-position: -2777px -102px;
  width: 20px;
  height: 17px;
}

.rms__sidebar__item.selected .rule__engine__icon {
  background-position: -2777px -102px;
  width: 20px;
  height: 17px;
}

.parameters_createdBy_icon {

  background-position: -808px -355px;
  width: 17px;
  height: 15px;
}

.rms__sidebar__item:hover .parameters_createdBy_icon {
  background-position: -2393px -171px;
  width: 16px;
  height: 20px;

}

.rms__sidebar__item.selected .parameters_createdBy_icon {
  background-position: -2393px -171px;
  width: 16px;
  height: 20px;

}

.parameters_createdOn_icon {
  background-position: -846px -356px;
  width: 13px;
  height: 15px;
}

.parameters_deviceCount_icon {
  background-position: -772px -355px;
  width: 16px;
  height: 16px;
}



.rms__sidebar__item:hover .customer__management__icon {
  background-position: -2393px -171px;
  width: 16px;
  height: 20px;

}

.rms__sidebar__item.selected .customer__management__icon {
  background-position: -2393px -171px;
  width: 16px;
  height: 20px;

}




.app__sidebar .user__management__icon {
  background-position: -604px -82px;

  width: 16px;
  height: 15px;
}


.rms__login__logo {
  background-position: -49px -65px;
  width: 232px;
  height: 48px;
}

.rms__dashboard__refresh__icon {
  background-position: -1687px -180px;
  width: 19px;
  height: 19px;

}

.rms__chargerDashboard__refresh__icon {
  background-position: -1687px -180px;
  width: 19px;
  height: 19px;
  animation: rotate 3s linear;
}

.auto__refresh__component .rms__dashboard__refresh__icon.animation {
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }

}

.rms__table__dashboard-map__view__icon {
  background-position: -219px -158px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.rms__table__dashboard-map__view__icon.selected {
  background-position: -728px -220px;
  width: 32px;
  height: 32px;
}

.rms__table__dashboard-list__view__icon {
  background-position: -775px -259px;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  cursor: pointer;
}

.rms__table__dashboard-list__view__icon.selected {
  background-position: -776px -220px;
  width: 32px;
  height: 32px;
}

.rms__table__dashboard-grid__view__icon {
  background-position: -670px -259px;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  cursor: pointer;
}

.rms__table__dashboard-grid__view__icon.selected {
  background-position: -671px -220px;
  width: 32px;
  height: 32px;

}

.rms__table__dashboard-detailed__view__icon {
  background-position: -2103px -223px;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  cursor: pointer;
}

.rms__table__dashboard-detailed__view__icon.selected {
  background-position: -2103px -263px;
  width: 32px;
  height: 32px;
}

.rms__table__dashboard__add__new__btn {
  background-position: -1339px -79px;
  width: 21px;
  height: 21px;
}

.rms__table__dashboard__add__new__btn__white {
  background-position: -509px -164px;
  width: 21px;
  height: 21px;
}

.rms__table__dashboard__add__filter__btn {
  background-position: -1601px -82px;
  width: 20px;
  height: 14px;
}

.rms__table__dashboard__add__new__btn__white {
  background-position: -509px -164px;
  width: 21px;
  height: 21px;
}

.rms__table__dashboard__export__csv__icon {
  background-position: -494px -274px;
  width: 14px;
  height: 14px;
}


.rms__custom__search__box__icon {
  background-position: -643px -230px;
  width: 13px;
  height: 13px;
}

.rms__up-white-arrow {
  background-position: -565px -235px;
  width: 9px;
  height: 6px;
}

.rms__down-white-arrow {
  background-position: -477px -236px;
  width: 9px;
  height: 6px;
}

.rms__sort__icon-asc {
  background-position: -177px -274px;
  width: 9px;
  height: 14px;
}

.rms__sort__icon-desc {
  background-position: -215px -274px;
  width: 9px;
  height: 14px;
}

.rms__left-white-arrow {
  background-position: -611px -233px;
  width: 6px;
  height: 9px;
}

.rms__right-white-arrow {
  background-position: -524px -234px;
  width: 6px;
  height: 9px;
}

.rms__right-up-arrow {
  background-position: -524px -234px;
  width: 6px;
  height: 9px;
  transform: rotate(270deg);
}


.rms__right-black-arrow {
  background-position: -349px -233px;
  width: 6px;
  height: 9px;
}

.rms__down-black-arrow {
  background-position: -302px -235px;
  width: 9px;
  height: 6px;
}

.dashboard__customer__card__icon {
  background-position: -2497px -68px;
  width: 17px;
  height: 17px;
}

.dashboard__charging__station__icon {
  background-position: -2407px -68px;
  width: 23px;
  height: 17px;
}

.dashboard__total__charger__icon {
  background-position: -2629px -68px;
  width: 18px;
  height: 18px;
}

.dashboard__online__charger__icon {
  background-position: -2539px -66px;
  width: 22px;
  height: 22px;
}

.dashboard__offline__charger__icon {
  background-position: -2319px -66px;
  width: 22px;
  height: 22px;
}

.dashboard__cms__connectivity__icon {
  background-position: -2142px -66px;
  width: 24px;
  height: 23px;

}

.rms__pencil__icon {
  background-position: -628px -167px;
  width: 16px;
  height: 15px;
  cursor: pointer;
}

.rms__pencil__white__icon {
  background-position: -962px -356px;
  width: 16px;
  height: 15px;
  cursor: default;
}


.rms__shield__icon {
  background-position: -706px -165px;
  width: 18px;
  height: 18px;

}

.rms__view__icon {
  background-position: -953px -83px;
  width: 18px;
  height: 12px;
}

.rms__delete__icon {
  background-position: -2489px -169px;
  width: 18px;
  height: 16px;
  cursor: pointer;
}

.status__online__charger__icon {
  background-position: -530px -270px;
  width: 16px;
  height: 16px;
}


.status__offline__charger__icon {
  background-position: -564px -271px;
  width: 15px;
  height: 14px;

}

.status__completed__parameter__icon {
  background-position: -1042px -271px;
  width: 16px;
  height: 16px;
}

.status__scheduled__parameter__icon {
  background-position: -1012px -83px;
  width: 14px;
  height: 14px;

}

.status__in-progress__parameter__icon {
  background-position: -1122px -83px;
  width: 15px;
  height: 15px;
}

.status__device_parameter__success__icon {
  background-position: -1228px -83px;
  width: 15px;
  height: 15px;
}

.status__device_parameter_failed__icon {
  background-position: -2450px -171px;
  width: 15px;
  height: 14px;
}

.status__active__user__icon {
  background-position: -1228px -83px;
  width: 15px;
  height: 15px;
}

.status__inactive__user__icon {
  background-position: -2450px -171px;
  width: 15px;
  height: 14px;
}

.grid__layout__expandable__down-icon {
  background-position: -2103px -172px;
  width: 16px;
  height: 9px;
  transform: rotate(180deg);
}

.grid__layout__expandable__up-icon {
  background-position: -2103px -172px;
  width: 16px;
  height: 9px;

}

.bulk_upload_template_info_icon {
  background-position: -737px -323px;
  width: 18px;
  height: 18px;
}

.no_alarm_icon {
  background-position: -2190px -106px;
  width: 18px;
  height: 20px;
}

.total__alarm__icon {
  background-position: -2232px -107px;
  width: 18px;
  height: 20px;
}

.critical__alarm__icon {
  background-position: -2365px -68px;
  width: 17px;
  height: 19px;
}

.major__alarm__icon {
  background-position: -2233px -67px;
  width: 18px;
  height: 20px;
}

.minor__alarm__icon {
  background-position: -2277px -68px;
  width: 17px;
  height: 19px;
}


.charger__card__online__status__icon {
  background-position: -1007px -145px;
  width: 55px;
  height: 56px;
}

.charger__card__offline__status__icon {
  background-position: -1895px -63px;
  width: 48px;
  height: 48px;
}

.charger__card__last__ping__icon {
  background-position: -1239px -158px;
  width: 56px;
  height: 43px;
}

.charger__card__charger__modal__icon {
  background-position: -1082px -147px;
  width: 54px;
  height: 54px;
}

.charger__card__alarm__status__icon {
  background-position: -1163px -142px;
  width: 49px;
  height: 54px;
}

.charger__card__software__version__icon {
  background-position: -1314px -156px;
  width: 51px;
  height: 45px;
}

.parameter__card__total__icon {
  background-position: -1991px -63px;
  width: 43px;
  height: 36px;
}

.parameter__card__success__icon {
  background-position: -790px -444px;
  width: 44px;
  height: 43px;
}

.parameter__card__faied__icon {
  background-position: -1803px -63px;
  width: 43px;
  height: 43px;
}

.parameter__card__in_progress__icon {
  background-position: -919px -445px;
  width: 43px;
  height: 43px;
}

.mobile__view__charging__station__icon {
  background-position: -986px -222px;
  width: 18px;
  height: 18px;
}

.mobile__view__location__icon {
  background-position: -907px -222px;
  width: 15px;
  height: 18px;
}


.mobile__view__charger__icon {
  background-position: -946px -222px;
  width: 17px;
  height: 17px;
}

.charger__panel__settings__all__icon {
  background-position: -2005px -181px;
  width: 20px;
  height: 11px;
}


.charger__panel__charger__system__icon {
  background-position: -1961px -178px;
  width: 20px;
  height: 18px;
}



.charger__panel__ccu__group__icon {
  background-position: -1829px -177px;
  width: 20px;
  height: 19px;
}

.charger__panel_settings__icon {
  background-position: -1784px -177px;
  width: 21px;
  height: 20px;
}


.charger__panel__network__config__icon {
  background-position: -1741px -175px;
  width: 20px;
  height: 23px;
}

.charger__panel__backend__config__icon {
  background-position: -2047px -176px;
  width: 24px;
  height: 20px;
}

.charger__panel__authentication__config__icon {
  background-position: -1918px -177px;
  width: 18px;
  height: 18px;
}

.charger__panel__ocpp__config__icon {
  background-position: -2047px -176px;
  width: 24px;
  height: 20px;
}

.charger__panel__web__login__mgt__icon {
  background-position: -1873px -177px;
  width: 20px;
  height: 19px;
}

.carousel__left__btn {
  background-position: -2949px -65px;
  width: 24px;
  height: 24px;
}

.carousel__right__btn {
  background-position: -3001px -69px;
  width: 24px;
  height: 24px;
}

.graph__tooltip__online__icon {
  background-position: -2539px -66px;
  width: 22px;
  height: 22px;
}

.graph__tooltip__offline__icon {
  background-position: -2319px -66px;
  width: 22px;
  height: 22px;
}

.graph__tooltip__faulty__icon {
  background-position: -1077px -270px;
  width: 17px;
  height: 16px;
}

.graph__tooltip__customer__health__status__icon {
  background-position: -1042px -271px;
  width: 16px;
  height: 16px;
}

.graph__tooltip__cms__connectivity__fail__icon {
  background-position: -1338px -265px;
  width: 22px;
  height: 21px;
}

.under__warranty__icon {
  background-position: -2255px -234px;
  width: 16px;
  height: 19px;
}

.no__warranty__icon {
  background-position: -2211px -234px;
  width: 16px;
  height: 19px;
}

.filter__cancel__icon {
  background-position: -215px -308px;
  width: 22px;
  height: 22px;
}

.map__tooltip__customer__icon {
  background-position: -1229px -269px;
  width: 16px;
  height: 16px;
}

.map__tooltip__connector__icon {
  background-position: -1150px -266px;
  width: 17px;
  height: 22px;
}

.map__tooltip__offline__icon {
  background-position: -1266px -267px;
  width: 20px;
  height: 20px;
}

.map__tooltip__online__icon {
  background-position: -1188px -267px;
  width: 20px;
  height: 20px;
}

.map__tooltip__faulty__icon {
  background-position: -1112px -270px;
  width: 16px;
  height: 15px;
}

.date__picker__calendar__icon {
  background-position: -1285px -233px;
  width: 20px;
  height: 20px;
}

.parameter__charger__icon {
  background-position: -2143px -168px;
  width: 21px;
  height: 30px;
}

.parameter__connector__icon {
  background-position: -2245px -169px;
  width: 18px;
  height: 29px;
}

.parameter__wifi__icon {
  background-position: -2294px -173px;
  width: 21px;
  height: 20px;
}

.system__info__settings__icon {
  background-position: -2192px -173px;
  width: 24px;
  height: 22px;
}

.system__info__cabinet__settings__icon {
  background-position: -2143px -168px;
  width: 21px;
  height: 30px;
}

.system__info__ac__settings__icon {
  background-position: -2245px -169px;
  width: 18px;
  height: 29px;
}

.system__info__communication__settings__icon {
  background-position: -2294px -173px;
  width: 21px;
  height: 20px;
}

.system__info__ac__gun__settings__icon {
  background-position: -2342px -171px;
  width: 23px;
  height: 23px;
}

.ac__input__parameters__icon {
  background-position: -375px -318px;
  width: 24px;
  height: 30px;
}

.communication__parameters__icon {
  background-position: -410px -322px;
  width: 30px;
  height: 23px;
}

.cabinet__status__parameters__icon {
  background-position: -335px -318px;
  width: 27px;
  height: 30px;
}

.system__alarms__icon {
  background-position: -455px -321px;
  width: 28px;
  height: 24px;
}

.connector__data__icon {
  background-position: -495px -320px;
  width: 26px;
  height: 26px;
}

.connector__fan__icon {
  background-position: -1035px -318px;
  width: 28px;
  height: 28px;
}

.no__alarms__icon {
  background-position: -254px -308px;
  width: 46px;
  height: 48px;
}

.system__information__icon {
  background-position: -539px -319px;
  width: 30px;
  height: 27px;
}

.system__level__rating__parameters__icon {
  background-position: -654px -319px;
  width: 30px;
  height: 27px;
}

.ocpp__parameters__icon {
  background-position: -615px -318px;
  width: 27px;
  height: 27px;

}

.network__parameters__icon {
  background-position: -691px -318px;
  width: 28px;
  height: 29px;
}

.system__configuration__icon {
  background-position: -574px -319px;
  width: 30px;
  height: 27px;
}

.enable_togge-icon {
  background-position: -389px -516px;
  width: 22px;
  height: 22px;
}

.disable_togge-icon {
  background-position: -417px -516px;
  width: 22px;
  height: 22px;
}

.dc__ccs__connector__icon {
  background-position: -329px -447px;
  width: 40px;
  height: 48px;
}

.dc__gbt__connector__icon {
  background-position: -449px -452px;
  width: 40px;
  height: 35px;
}

.dc__chademo__connector__icon {
  background-position: -509px -449px;
  width: 40px;
  height: 41px;
}

.ac__type2__connector__icon {
  background-position: -568px -452px;
  width: 40px;
  height: 35px;
}

.react__select__dropdown__cross__icon {
  background-position: -183px -351px;
  width: 8px;
  height: 8px;
  margin: 4px 4px 4px 0px !important;
}

.parameter_confirm_icon {
  background-position: -451px -522px;
  width: 14px;
  height: 12px;
}

.parameter_cancel_icon {
  background-position: -477px -522px;
  width: 11px;
  height: 11px
}

.panel_expand_all_icon {
  background-position: -673px -454px;
  width: 25px;
  height: 26px;
  cursor: pointer;
}

.panel_collapse_all_icon {
  background-position: -631px -455px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.panel_soft_reset_icon {
  background-position: -333px -578px;
  width: 20px;
  height: 21px;
}

.panel_hard_reset_icon {
  background-position: -377px -577px;
  width: 20px;
  height: 21px;
}

.panel_enable_system_icon {
  background-position: -421px -577px;
  width: 20px;
  height: 22px;
}

.panel_disable_system_icon {
  background-position: -464px -577px;
  width: 22px;
  height: 22px;
}

.panel_disable_connector_icon {
  background-position: -553px -577px;
  width: 22px;
  height: 23px;
}

.panel_enable_connector_icon {
  background-position: -508px -577px;
  width: 23px;
  height: 25px;
}

.panel_ftp_software_connector_icon {
  background-position: -596px -576px;
  width: 24px;
  height: 24px;
}

.panel_ftp_log_upload_icon {
  background-position: -642px -577px;
  width: 19px;
  height: 22px;
}

.panel_ftp_certificate_upgrade_icon {
  background-position: -683px -578px;
  width: 25px;
  height: 20px;
}

.panel_ftp_screen_saver_images_upgrade_icon {
  background-position: -728px -578px;
  width: 23px;
  height: 20px;
}

.panel_ftp_customer_logo_update_icon {
  background-position: -772px -577px;
  width: 23px;
  height: 21px;
}

.rms_info_icon {
  background-position: -737px -323px;
  width: 18px;
  height: 18px;
}

.rms_info_icon-black {
  background-position: -770px -325px;
  width: 14px;
  height: 14px;
}

.rms__user_mobile_view_phno_icon {
  background-position: -808px -325px;
  width: 17px;
  height: 15px;
}

.rms__user_mobile_view_mail_icon {
  background-position: -847px -326px;
  width: 17px;
  height: 15px;
}

.rms__user_mobile_view_role_type_icon {
  background-position: -922px -324px;
  width: 17px;
  height: 15px;
}

.rms__user_mobile_view_user_type_icon {
  background-position: -961px -324px;
  width: 17px;
  height: 15px;
}

.rms__grey_copy_clipboard_icon {
  background-position: -1000px -325px;
  width: 15px;
  height: 15px;
}

.rms__black_copy_clipboard_icon {
  background-position: -885px -325px;
  width: 15px;
  height: 15px;
}

.rms_history_icon_customer {
  background-position: -704px -374px;
  width: 32px;
  height: 32px;
}

.rms_history_outlined_icon_customer {
  background-position: -748px -380px;
  width: 32px;
  height: 32px;
}

.mobile__view__time__icon {
  background-position: -2078px -73px;
  width: 17px;
  height: 18px;
}

.no__connectivity__medium__icon {
  background-position: -259px -515px;
  width: 46px;
  height: 40px;
}

.no__charger__data__icon {
  background-position: -105px -398px;
  width: 148px;
  height: 166px;
}

.panel_calibrate_voltage_icon {
  background-position: -1006px -577px;
  width: 24px;
  height: 24px;
}

.panel_calibrate_display_icon {
  background-position: -1046px -577px;
  width: 24px;
  height: 24px;
}

.panel_clear_txn_data_icon {
  background-position: -1087px -578px;
  width: 24px;
  height: 24px;
}

.panel_clear_charge_records_icon {
  background-position: -819px -579px;
  width: 20px;
  height: 20px;
}

.panel_clear_history_alarm_records_icon {
  background-position: -865px -578px;
  width: 24px;
  height: 24px;
}

.panel_clear_authorization_cache_icon {
  background-position: -912px -578px;
  width: 20px;
  height: 23px;
}

.panel_clear_local_list_icon {
  background-position: -962px -578px;
  width: 19px;
  height: 22px;
}

.rule_engine_parameter_icon {
  background-position: -404px -274px;
  width: 18px;
  height: 14px;
}

.rule_engine_running_status_icon {
  background-position: -132px -657px;
  width: 17px;
  height: 17px;
}

.rule_engine_error_status_icon {
  background-position: -196px -657px;
  width: 17px;
  height: 17px;
}

.rule_engine_draft_status_icon {
  background-position: -228px -657px;
  width: 17px;
  height: 17px;
}

.rule_engine_stopped_status_icon {
  background-position: -164px -657px;
  width: 17px;
  height: 17px;
}

.rule_engine_all_status_icon {
  background-position: -100px -657px;
  width: 17px;
  height: 17px;
}

.rule_engine_running_status_icon_selected {
  background-position: -133px -677px;
  width: 16px;
  height: 16px;
}

.rule_engine_error_status_icon_selected {
  background-position: -197px -677px;
  width: 16px;
  height: 16px;
}

.rule_engine_draft_status_icon_selected {
  background-position: -229px -677px;
  width: 16px;
  height: 16px;
}

.rule_engine_stopped_status_icon_selected {
  background-position: -165px -677px;
  width: 16px;
  height: 16px;
}

.rule_engine_all_status_icon_selected {
  background-position: -101px -677px;
  width: 16px;
  height: 16px;
}

.rule__engine__execution__button__icon {
  background-position: -2746px -103px;
  width: 50px;
  height: 50px;
}

.configure__notification__header__list__icon {
  background-position: -1084px -353px;
  height: 28px;
  width: 28px;
  margin-right: 10px;
  cursor: pointer;
}

.configure__notification__header__list__icon.selected {
  background-position: -1084px -318px;
  width: 28px;
  height: 28px;
}

.configure__notification__header__grid__icon {
  background-position: -1122px -353px;
  height: 28px;
  width: 28px;
  margin-right: 10px;
  cursor: pointer;
}

.configure__notification__header__grid__icon.selected {
  background-position: -1122px -318px;
  width: 28px;
  height: 28px;
}

.configure_notification_sms_email_icon {
  background-position: -1162px -318px;
  width: 18px;
  height: 18px;
}

.execution_plan_button_edit {
  background-position: -596px -746px;
  width: 22px;
  height: 16px;
}

.execution_plan_button_define {
  background-position: -308px -749px;
  width: 22px;
  height: 16px;
}

.action_control_cogs {
  background-position: -268px -752px;
  width: 22px;
  height: 16px;
}

.sidebar-handle {
  background-position: -145px -749px;
  width: 22px;
  height: 16px;
}

.set-parameter-sidebar {
  background-position: -403px -749px;
  width: 22px;
  height: 16px;
}

.set-parameter-sidebar-1 {
  background-position: -403px -749px;
  width: 22px;
  height: 15px;
}

.set-parameter-sidebar-mt {
  background-position: -403px -749px;
  width: 22px;
  height: 16px;
  margin-top: 3px;
}

.set-parameter-sidebar-gray {
  background-position: -403px -781px;
  width: 16px;
  height: 16px;
}

.remote-command-sidebar {
  background-position: -466px -748px;
  width: 22px;
  height: 16px;
}

.remote-command-sidebar-1 {
  background-position: -466px -748px;
  width: 22px;
  height: 13px;
}

.remote-command-sidebar-gray {
  background-position: -465px -780px;
  width: 13px;
  height: 13px;
}



.raise-a-ticket-sidebar {
  background-position: -534px -752px;
  width: 22px;
  height: 13px;
}

.raise-a-ticket-sidebar-1 {
  background-position: -534px -752px;
  width: 22px;
  height: 11.5px;
}

.raise-a-ticket-sidebar-gray {
  background-position: -496px -780px;
  width: 22px;
  height: 13px;
}

.send-notification-sidebar {
  background-position: -497px -748px;
  width: 18px;
  height: 14px;
}

.send-notification-sidebar-1 {
  background-position: -497px -748px;
  width: 18px;
  height: 13px;
}

.send-notification-sidebar-gray {
  background-position: -534px -784px;
  width: 16px;
  height: 10px;
}

.trigger-parameters-sidebar {
  background-position: -171px -749px;
  width: 14px;
  height: 14px;
}

.flowcontrol-gray {
  background-position: -564px -745px;
  width: 19px;
  height: 19px;
}

.trigger-alarms-sidebar-1 {
  background-position: -204px -748px;
  width: 13px;
  height: 19px;
}

.trigger-alarms-sidebar {
  background-position: -204px -748px;
  width: 13px;
  height: 15px;
}


.parameters-gray {
  background-position: -170px -786px;
  width: 15px;
  height: 15px;
}

.alarms-gray {
  background-position: -204px -786px;
  width: 13px;
  height: 15px;
}

.trigger-point-sidebar {
  background-position: -112px -749px;
  width: 22px;
  height: 19px;
}

.trigger-icon-black-sidebar {
  background-position: -80px -749px;
  width: 22px;
  height: 19px;
}

.define-execution-sidebar {
  background-position: -2565px -101px;
  width: 22px;
  height: 19px;
}

.define-execution-edit-pencil-sidebar {
  background-position: -1556px -81px;
  width: 22px;
  height: 19px;
}

.then-block-execution-plan {
  background-position: -438px -749px;
  width: 17px;
  height: 13px;
  margin-top: 2px;
  scale: 1.2;
}

.rule__engine__form__schedule__execution__icon {
  background-position: -101px -309px;
  width: 18px;
  height: 18px;
}

.rule__engine__form__calendar__icon {
  background-position: -846px -356px;
  width: 13px;
  height: 15px;
}

.define_execution__bolt_black__icon {
  background-position: -80px -749px;
  width: 19px;
  height: 18px;
}

.side__popover__expand__icon {
  background-position: -410px -667px;
  height: 18px;
  width: 18px;
}

.side__popover__shrink__icon {
  background-position: -450px -667px;
  height: 18px;
  width: 18px;
}

.rule__engine__form__execution__summary__icon {
  background-position: -492px -667px;
  width: 17px;
  height: 19px;
}

.rule__engine__form__update__logs__icon {
  background-position: -528px -667px;
  width: 16px;
  height: 18px;
}

.execution_plan_summary_icon_blue {
  background-position: -261px -660px;
  width: 23px;
  height: 31px;
}

.execution_plan_summary_icon_black {
  background-position: -564px -660px;
  width: 23px;
  height: 31px;
}

.scheduleTime-sidebar {
  background-position: -170px -818px;
  width: 16px;
  height: 14px;
}

.scheduleTime-sidebar-gray {
  background-position: -203px -818px;
  width: 14px;
  height: 14px;
}