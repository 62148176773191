.configure_notification_history_timeline_container {
  min-width: 600px;
  max-width: 100%;
  /* height: 150px; */
}


.history__timeline__container {
  height: 100px;
  max-height: 100px;
}

.highcharts-minor-grid-line {
  stroke: lightgray !important;
  stroke-width: 1px !important;
}