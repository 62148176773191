.rms__icon__date__picker__wrap {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: var(--font-color);
}

.rms__icon__date__picker__wrap > div {
    font-weight: 700;
}

.rms__icon__date__picker__value {
    display: flex;
    gap: 8px;
}

.rms__icon__date__picker__placeholder {
    display: flex;
    gap: 8px;
    font-weight: 500 !important;
    color: var(--light-grey-font-color);
}
