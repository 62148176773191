.custom__radio__form {
  display: flex;
  flex-direction: column;
}

.custom__radio__form-header {
  font-weight: 300;
  font-size: 14px;
  margin: 20px 0px;
}

.custom__radio__form-header_custom {
  font-weight: 500;
  font-size: 17px;
  margin: 20px 0px;
  color: var(--primary-text-color);
}

.custom__radio__form-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.custom__radio__form-content-top {
  display: flex;
  align-items: center;
  font-size: 10px;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  border: 2px solid rgba(248, 248, 248, 1);
  box-shadow: 0px 2px 2px 0px rgba(140, 140, 140, 0.302);
}

.custom__radio__form-content-single-selection-container {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 15px;
  border: 2px solid rgba(248, 248, 248, 1);
}

.custom__radio__form-content-single-selection-container-no-padding {
  margin-top: 0px;
  padding: 0 15px 15px 15px;
  /* border: 2px solid rgba(248, 248, 248, 1); */
  /* border-radius: 10px; */
  background-color: rgba(250,250,250,1);
}

.custom__radio__form-search-box-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 20px;
}

.custom__radio__form-search-box-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 20px;
  margin-top: 20px;
}


.custom__radio__form-search-box-container .search__wrap-right {
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  column-gap: 10px;
}

.custom-class {
  display: flex;
}

.wrap_content_row_custom {
  min-width: 150px;
}

.custom__radio__form-select-all-check-box {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
}

.custom__radio__form-selected-values-container {
  display: flex;
  overflow-x: auto;
  padding: 0px !important;
}

.custom__radio__form-selected-values-container .chip-item {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 12px;
}


.custom__radio__form-checkbox-list-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  /* flex-direction: column; */
}

.custom__radio__form-checkbox-individual-column {
  /* display: flex; */
  /* flex-direction: column; */
  margin-right: 20px;
}

.custom__radio__form-checkbox-item {
  display: flex;
  align-items: center;
  width: 150px;
  margin: 15px 0px 10px 12px;
  cursor: pointer;
  font-size: 14px;

}

.custom__radio__form-checkbox {
  display: flex;
  align-items: center;
  width: 100%;
}

.custom__radio__form-checkbox_custom_1 {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
}

.custom__radio__form-list-header {
  font-weight: 600;
  color: var(--placeholder-color);
}

.custom__radio__form-list-header_small {
  font-weight: 600;
  color: var(--placeholder-color);
  font-size: 12px;
}

.custom__radio__form-checkbox .custom__radio__form-checkbox-text {
  padding-left: 5px;
}

.custom__radio__form-checkbox-list-container .no-entity-found {
  display: flex;
  margin: auto;
  padding: 20px;
}

.custom__radio__form-checkbox-individual-row-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
}