.customer__form__wrap {
  padding: 16px 20px;
  min-height: calc(100dvh - 48px);
  height: calc(100dvh - 48px);
  display: flex;
  flex-direction: column;
}

.customer__form__wrap-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
}

.customer__form__wrap-header {
  display: flex;
}

.customer__form__wrap-header-wrap-buttons {
  display: flex;
  height: 32px;
}

.customer__form__wrap-header-text {
  padding-top: 15px;
  font-weight: 300;
  font-size: 14px;
}

.customer__form__wrap-header-text > h2{
  padding-top: 20px;
  font-size: small;
  font-weight: 500;
}

.customer__form__wrap-content-row {
  display: grid;
  padding: 15px 0px 5px;
}

.customer__form__wrap-content-header-row {
  display: grid;
  padding: 15px 0px;
}

.customer__form__wrap-content-row.grid-5 {
  padding-left: 20px;
  padding-right: 20px;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
}

.customer__form__wrap-content-row.grid-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
}

.customer__form__wrap-content-row.grid-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
}

.customer__form__wrap-content-row.grid-2 {
  grid-template-columns: 1fr  1fr;
  grid-column-gap: 15px;
}
.add__charger__back__btn,
.add__parameter__back__btn{
  width: fit-content;
}



.customer__form__wrap-content-row .rms__custom__input__field #add_customer_email {
  width: 66.67% !important;
}

.customer__form__wrap-content-row.form-row {
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.customer__form__wrap-pre-content {
  background-color: #f7f7f7;
  width: 100%;
}

.grid-5.customer__form__wrap-pre-content .column {
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;
  flex:1;
}

.row .bold-text {
  font-weight: 650;
  font-size: 13px;
}

.column .row {
  font-weight: 300;
  font-size: 14px;
}

.customer__form__wrap-header-link-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.customer__form__wrap-content-column .flag-dropdown {
  visibility: hidden;
}

.add__station__back-btn_wrap .link__btn-text {
  font-weight: 1400;
}

.link__button__wrap {
  display: flex;
}

.link__button__wrap .link-text {
  cursor: pointer;
  font-family: var(--font-family);
  color: var(--secondary-blue-color);
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.rms__customer__input__error__message {
  font-size: 10px;
  font-weight: 500;
  font-family: var(--font-family);
  color: var(--red-color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
  padding-top: 5px;
}

.customer__form__wrap-footer {
  margin-top: auto;
  position: relative;
  width: 100%;
  padding-top: 10px;
}

.customer__form__wrap-content {
  margin: 10px 0px;
  height: 100%;
  overflow-y: auto;
}

.customer__form__wrap-content .custom__form__wrap {
  padding:unset;
  height:auto;
  min-height: auto;
}

.customer__form__wrap-content .rms__table__dashboard {
  padding: unset;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.customer__form-view-charger-text {
  color: var(--secondary-blue-color);
    cursor: pointer;
    font-family: Inter, sans-serif;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
}

.customer__form__wrap .rms__table__dashboard__table__wrap {
  min-height: 200px;
}

.customer__form__wrap .rms__table__dashboard__search__wrap-right {
  grid-template-columns: repeat(4,minmax(150px,1fr));
}

.side_popover_table-header {
  width: 20px;
  font-family: Inter, sans-serif !Important;
  font-weight:600 !Important;
  font-size: 14px !Important;
}