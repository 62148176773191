.flow__controls__side__popover__wrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
}

.flow__control__side__popover__column__label{
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 12px 8px;
}

.flow__control__side__popover__dropdown{
    display: flex;
    flex-direction: row;
    padding: 12px 8px;
    gap: 12px;
}

.flow__control__side__popover__logical__operator{
    padding: 12px 8px;
    width: 100px;
}

.flow__control__side__popover__label {
    color: var(--primary-text-color);
    font-size: 14px;
    /* font-weight: 600; */
    line-height: 14.52px;

}

.flow__control__side__popover__parameter__label{
    width: 240px;
    /* flex: 2; */
    
}

.flow__control__side__popover__operator__label{
    width: 180px;
    /* flex: 1.5; */
    
}

.flow__control__side__popover__value__label{
    display: flex;
    flex-direction: row;
    gap: 6px;
    width: 180px;
    align-items: baseline;
    /* flex: 1.5; */
}

.rule__engine__if__then__else__side__popover__footer{
    margin-top: auto;
    padding-bottom: 32px;
}

.flow__control__side__popover__add__condition {
    font-size: 16px;
    font-family: var(--font-family-bold);
    color: var(--primary-text-color);
    font-weight: 400;
}


