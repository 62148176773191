.alert__logic__row__label {
    margin: 6px 0px 7px 0px ;
    font-size: 12px;
    color: var(--primary-text-color);
}

.alert__logic__row__content{
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.alert__logic__alarm__dropdown{
    width: 33%;
}

.alert__logic__alarm__continues__for{
    width: 30%;
}

.alert__logic__minute__input{
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    color: var(--primary-text-color);
}

.create_rule_alert_section_headings {
    font-size: 17px;
    font-weight: 500;
    /* margin: 20px 0 20px; */
    color: var(--primary-text-color);
}

.create__rule__alert__logic__wrapper{
    /* background-color: rgba(250, 250, 250, 1); */
    padding: 0 15px 15px 0px;
    margin-top: 20px;
}

.create__rule__alert__logic__wrapper .alert__logic__row__content{
    background-color: rgba(250, 250, 250, 1);
    padding: 15px 0px 15px 15px;
    margin-top: 20px;
}

.create__rule__alert__logic__header__info__wrapper{
    display: flex;
    flex-direction: row;

}