.spurious_charger_form_wrap {
  height: calc(100dvh - 148px);
  display: flex;
  flex-direction: column;

}
.spurious-chargers-charger-serial-no {
  padding-top: 24px;
  width: 55%
}

.edit-spurious-charger-content-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}