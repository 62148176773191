.alarm__summary-card-filled {
  border-radius: 8px;
  padding: 11px 10px 11px 19px;
  display: flex;
  justify-content: space-between;
  height: 90px;
  border-radius: 8px;
}

.alarm__summary-card-filled:last-of-type {
  margin-right: 0px;
}

.alarm__summary-card-header-text {
  font-size: 13px;
  font-family: var(--font-family-bold);
  color: var(--white-color);
  letter-spacing: -0.01px;
  font-weight: 500;
}

.alarm__summary-card-border {
  height: 4px;
  width: 100%;
}

.alarm__summary-card-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
}

.alarm__summary-card-footer-text {
  font-size: 20px;
  font-family: var(--font-family-bold);
  color: var(--white-color);
  font-weight: 500;
  white-space: pre;
  letter-spacing: -0.01px;
}

.alarm__summary-card-right {
  display: flex;
  align-items: center;
}

.alarm__summary-card-footer-sub-text {
  font-size: 14px;
  font-family: var(--font-family-bold);
  color: var(--white-color);
  font-weight: 500;
  /* white-space: pre; */
  letter-spacing: -0.01px;
  margin-top: 2px;
}

.sub__header__grey__card {
  background-color: var(--grey-card-bg);
  padding: 16px;
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
}

.sub__header__grey__card:last-of-type {
  margin-right: 0px;
}

.sub__header__grey__card__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  column-gap: 10px;

}

.sub__header__grey__card__content__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub__header__grey__card__header {
  font-size: 14px;
  font-family: var(--font-family-bold);
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub__header__grey__card__content {
  padding: 0px 20px;
  border-right: 1.5px solid white;
}

.sub__header__grey__card__content:last-child {
  border-right: 0px;
}

.sub__header__grey__card__content-value {
  margin-top: 5px;
}

.sub__header__grey__card__content-title {
  font-size: 12px;
  font-family: var(--font-family-regular);
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
  white-space: pre
}

.sub__header__grey__card__content-value {
  font-size: 15px;
  font-family: var(--font-family-semi-medium);
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
}