.schedule__time__node__subHeading__label {
    font-size: 16px;
    font-weight: 600;
    min-width: 35px;
}

/* .schedule__time__node__subHeading__label__value{

} */

.schedule__time__node_content__wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
}