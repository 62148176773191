.rule__engine__raise__a__ticket__side__popover__row__label {

    font-size: 12px;
    font-family: var(--font-family-regular);
    color: var(--primary-text-color);
    padding: 0px 0px 5px 2px;
}

.rule__engine__raise__a__ticket__popover__row__value {
    
    font-size: 14px;
    font-family: var(--font-family-regular);
    color: var(--primary-text-color);
    padding: 0px 0px 5px 5px;
    font-weight: 500;
}

.rule__engine__raise__a__ticket__side__popover__wrapper{

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
}
