.map__layout__container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.map__container {
  width: 100%;
}

.map__tooltip__container {
  background: var(--white-color);
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--tooltip-background-color);
  font-family: var(--font-family);
  padding: 10px;
  font-size: 12px;
  letter-spacing: -0.01px;
  font-family: var(--font-family-regular);
}

.map__layout__container .gm-style-iw {
  padding: 0px !important;
}

.map__layout__container .gm-style .gm-style-iw-c {
  background: var(--white-color);
}

.map__layout__container .gm-style-iw-d {
  display: flex;
  overflow: auto !important;
}

.map__layout__container .gm-style-iw-d::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.map__layout__container button.gm-ui-hover-effect {
  visibility: hidden;
  width: 0px !important;
  height: 0px !important;
}

.map__layout__container .gm-style .gm-style-iw-tc::after {
  background: var(--white-color);
}

.map__layout__container .gm-style .gm-style-iw-tc {
  filter: none !important;
}

.map__layout__container .gm-ui-hover-effect {
  display: none;
}

.map__tooltip__container-header-btn {
  all: unset;
  text-decoration: underline;
  text-underline-offset: 5px;
  padding-bottom: 10px;
  cursor: pointer;
  color: #3dcdcd;
  font-size: 12px;
  font-family: var(--font-family-regular);
  letter-spacing: -0.01px;
  font-weight: 500;
}

.map__tooltip__container-content {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.map__tooltip__container-content-row .map__tooltip__container-content-icon {
  padding: 0px !important;
  cursor: default;
}

.map__tooltip__container-content-row {
  display: flex;
  padding: 4px;
}

.map__tooltip__container-content-icon-wrap {
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map__tooltip__container-content-text {
  display: flex;
  align-items: center;
}