@media screen and (max-width: 780px) {
  .app__route__container {
    margin-left: 0px !important;
    width: 100% !important;
  }

  .navbar__profile__name {
    display: none;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 769px) {
  .app__dashboard__header-cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .sub__header__grey__card__wrapper {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
    row-gap: 10px;
  }

  .charger__panel__status__dashboard-top {
    flex-direction: column;
  }

  .charger__status__details__wrap__container {
    /* align-items: center;
    justify-content: center; */
    width: 100%;
  }

  .charger__status__history__dashboard{
    padding-top: 16px;
    width: 100%;
  }

  .dashboard__header__skeleton__wrap {
    grid-template-columns: repeat(3, 1fr);
  }

  .app__dashboard__body-container {
    flex-direction: column;
  }

  .app__dashboard__charts__body-container {
    flex-direction: column;
  }
  .app__dashboard__charts__body-container > :first-child {
    width: 100%;
    flex-direction: column;
  }

  .app__dashboard__charts__body-container > :last-child {
    width: 100%;
    flex-direction: column;
  }

  .charger__health__status__container {
    width: 100%;
  }

  .alarm__summary__container {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .charger__working__status-sub-header-wrap {
    flex-direction: column;
  }

  .charger__panel-alarm-cards {
    justify-content: initial;
  }

  .charger__panel-alarm-card {
    width: 31.33%;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .charger__panel-alarm-cards {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
  }

  .alarms__dashboard__container-filter-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .rms__table__dashboard__search__wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .connectivity__medium__status__container{
    width: 100%;
    margin-right: 20px;
  }

  .charger__working__status__container{
    width: 100%;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1366px) {
  .alarms__dashboard__container-header-filter-btn-wrap{
   margin-top: 10px;
  }

  .alarms__dashboard__container-filter-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .rms__table__dashboard__search__wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .rms__table__dashboard__search__wrap-right {
    margin-top: 10px;
  }

  .sub__header__grey__card__wrapper {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }

}

@media screen and (max-width: 768px) {
  .app__dashboard__header-cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .dashboard__header__skeleton__wrap {
    grid-template-columns: repeat(3, 1fr);
  }

  .charger__panel__status__dashboard-top {
    flex-direction: column;
  }


  .customer__wise__dropdown__wrap {
    margin-top: 20px;
  }

  .app__dashboard__body-container {
    flex-direction: column;
  }

  .app__dashboard__charts__body-container {
    flex-direction: column;
  }

  .app__dashboard__charts__body-container > :first-child {
    width: 100%;
    flex-direction: column;
  }

  .app__dashboard__charts__body-container > :last-child {
    width: 100%;
    flex-direction: column;
  }

  .charger__status__details__wrap__container {
    width: 100%;
  }

  .charger__status__history__dashboard{
    padding-top: 16px;
    width: 100%;
  }


  .charger__health__status__container {
    width: 100%;
  }

  .alarm__summary__container {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .charger__panel-alarm-cards {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
  }

  .sub__header__grey__card__wrapper {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    row-gap: 10px;
  }

  .connectivity__medium__status__container {
    width: 100%;
    margin-right: 20px;
  }

  .charger__working__status__container {
    width: 100%;
    margin-left: 20px;
  }

}

@media screen and (max-width: 800px) {
  .app__navbar__left {
    width: fit-content;
  }

  .app__navbar__right {
    width: fit-content;
    padding-left: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .alarms__dashboard__container-header-filter-btn-wrap{
    grid-template-columns: repeat(3,minmax(180px,1fr)) !important;
  }

  .alarms__dashboard__container-filter-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .rms__table__dashboard__search__wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .rms__table__dashboard__search__wrap-right {
    margin-top: 10px;
    grid-template-columns: repeat(3,minmax(180px,1fr));
  }

  .alarm__history__search__box {
    width: 100%;
    display: flex;
  }

  .alarm__history__search__box .MuiInputBase-root {
    min-width: 400px !important;
    width: 100% !important;
  }

  .charger__panel__alarm__history__header-actions {
    width: 100%;
    padding-top: 10px;
    justify-content: space-between;
    display: flex;
  }

  .app__dashboard__body-container,

  .app__dashboard__charts__body-container{
    flex-direction: column;
  }

  .app__dashboard__charts__body-container > :first-child {
    width: 100%;
    flex-direction: column;
  }

  .app__dashboard__charts__body-container > :last-child {
    width: 100%;
    flex-direction: column;
  }

  .app__dashboard__header-container {
    flex-direction: column;
  }

  .charger__health__status__container {
    width: 100%;
  }

  .alarm__summary__container {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .custom__radio__form-search-box-container{
    flex-direction: column;
    align-items: flex-start;
  }

  .connectivity__medium__status__container {
    width: 100%;
    margin-right: 20px;
  }
  
  .charger__working__status__container {
    width: 100%;
    margin-left: 20px;
  }
}

@media screen and (max-width: 600px) {
  .rms__table__dashboard {
    padding-top: 20px;
  }

  .grid__layout__table__wrap .MuiDataGrid-virtualScrollerContent {
    min-height: 200px !important;
  }

  .rms__table__dashboard-header-wrap {
    display: flex;
    align-items: center;
    max-height: 40px;
    border-bottom: 0px;
    flex-direction: row;
  }

  .add__new__btn {
    background-color: transparent !important;
    border-radius: 0px !important;
    border: 0px !important;
  }

  .add__filter__btn {
    margin-right: 0px !important;
  }

  .add__new__btn:hover {
    background-color: transparent !important;
    border-radius: 0px !important;
    border: 0px !important;
  }

  .rms__table__dashboard-header {
    max-width: 70% !important;
  }

  .rms__table__dashboard-sub-header-wrap {
    width: 30% !important;
    max-width: 30% !important;
    justify-content: flex-end;
    margin-top: 0px;
  }

  .rms__table__dashboard__search__wrap {
    flex-direction: column;
  }

  .rms__table__dashboard__view__options__wrap {
    width: 100%;
  }

  .rms__table__dashboard__search__wrap-right,
  .alarms__dashboard__container-header-filter-btn-wrap {
    margin-top: 10px;
    grid-template-columns: repeat(2,minmax(180px,1fr)) !important;
  }

  .rms__table__dashboard__search__wrap-left {
    width: 100%;
  }

  .app__navbar__search__wrap {
    display: none;
  }

  .rms__table__dashboard-sub-header-wrap .tooltip__icon__wrapper-export,
  .alarms__dashboard__container-header-right-export .tooltip__icon__wrapper-export
  {
    background-color: var(--secondary-blue-color);
    margin-right: 10px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  .grid__layout__expandable__container__non__toggle__wrap {
    flex-direction: column;
    width: 95%;
  }

  .grid__layout__expandable__container__toggle__wrap {
    width: 5%;
    padding-bottom: 10px;
  }

  .grid__layout__expandable__container {
    padding: 5px 16px;
    margin-bottom: 0px;
  }

  .grid__layout__expandable__container-header-text {
    font-size: 14px;
  }

  .grid__layout__mobile__expandable__container__header-count {
    font-size: 14px;
  }

  .grid__layout__expandable__container-wrap {
    justify-content: space-between;
  }

  .grid__layout__expandable__mobile__container-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .grid__layout__expandable__container-wrap {
    height: auto;
  }

  .grid__layout__expandable__down-icon {
    margin-top: 10px;
  }

  .grid__layout__expandable__up-icon {
    margin-top: 10px;
  }

  .grid__layout__mobile__expandable__container__header__wrap {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }

  .grid__layout__mobile__expandable__container__header__wrap:last-of-type {
    margin-right: 0px;
  }

  .grid__layout__mobile__expandable__container__header-icon {
    cursor: default;
    margin-right: 10px;
  }

  .grid__layout__table .MuiDataGrid-virtualScrollerContent {
    min-height: 200px !important;
  }

  .grid__layout__table .MuiDataGrid-main {
    border-bottom: 1px solid var(--border-color);
  }

  .rms__table__dashboard__no__data {
    border-bottom: 0px;
  }

  .grid__layout__table .rms__table__row__cards {
    margin-left: 10px;
    margin-right: 10px;
  }

  .alarms__dashboard__container-cards-wrap {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }

  .alarms__dashboard__container-filter-header .alarm__history__search__box .MuiInputBase-root {
    min-width: 100% !important;
  }
}

/* Tablet Break point */

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .app__dashboard__header-cards {
    justify-content: left;
  }
}

/* mobile Break point */

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .app__dashboard__header-card.onlineChargers {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 520px) {
  .app__dashboard__header-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard__header__skeleton__wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 480px) and (min-width: 375px) {
  .app__dashboard__header-card {
    min-width: 140px;
    min-height: 115px;
  }

  .app__dashboard__header-cards .card__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .card__header__text {
    margin-left: 0px;
    margin-top: 6px;
  }

  .app__dashboard__header-cards .card__header__count {
    font-size: 32px;
  }

  .charger__panel-alarm-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 330px) and (max-width: 370px) {
  .app__dashboard__header-cards {
    grid-template-columns: 1fr;
  }

  .dashboard__header__skeleton__wrap {
    grid-template-columns: 1fr;
  }

  .card__header__icon {
    margin-right: 6px !important;
  }

  .card__header__text {
    margin-left: 6px;
    font-size: 32px;
  }

  .app__dashboard__header-cards .card__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .card__header__text {
    margin-left: 0px;
    margin-top: 6px;
    font-size: 32px;
  }

  .charger__panel-alarm-cards {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 330px) {
  .app__dashboard__header-card {
    width: 100%;
  }

}