.rectangular__skeleton__wrapper {
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
}

.table__skeleton__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.charger__panel__skeleton__header__wrap {
  display: flex;
  height: 30px;
  margin-top: 4px;
}

.charger__panel__skeleton__header__wrap .charger__panel__skeleton__header {
  padding: 0px !important;
}

.charger__panel__skeleton__header__wrap .rectangular__skeleton {
  border-radius: 4px;
}

.charger__panel__header__wrap__skeleton__card {
  display: grid;
  height: 90px;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 8px;
  margin-bottom: 20px;
}

.charger__panel__header__wrap__skeleton__card .charger__panel__header__card {
  padding: 0px !important;
}

.charger__panel__header__wrap__skeleton__card .rectangular__skeleton {
  border-radius: 8px;
}

.charger__status__skeleton-top {
  display: flex;
}

.charger__status__skeleton {
  display: flex;
  flex-direction: column;
}

.charger__status__connector {
  background-color: var(--charger-status-idle-dark-color);
  width: 112px;
}

.charger__status__connector .charger__status__skeleton {
  padding: 8px !important;
}

.charger__status__connector-top {
  height: 45px;
}

.charger__status__connector-bottom {
  height: 45px;
  height: 103px;


}

.charger__status__skeleton-bottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

}

.charger__status__skeleton-table {
  display: flex;
  flex-direction: column;

  padding: 8px;
}

.charger__status__skeleton-table.idle {
  background-color: var(--charger-status-idle-table-color);
  border-radius: 0px 8px 8px 8px;
}

.charger__status__skeleton-table.charging {
  background-color: var(--charger-status-charging-table-color);
  border-radius: 8px 0px 8px 8px;
}


.charger__status__skeleton-row {
  height: 50px;

}

.charger__status__skeleton-row .charger__status__skeleton-cell {
  padding: 4px;

}

.charger__status__skeleton-row .rectangular__skeleton {
  border-radius: 4px;
}

.charger__history__graph__skeleton {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.charger__history__graph__skeleton-header {
  height: 60px;
}

.charger__history__graph__skeleton-header .rectangular__skeleton__wrapper {
  padding: 8px;
}

.charger__history__graph__skeleton-header .rectangular__skeleton {
  border-radius: 4px;
}

.charger__history__graph__skeleton-graph {
  height: 235px;
}

.charger__history__graph__skeleton-graph .rectangular__skeleton {
  border-radius: 4px;
}

.charger__history__graph__skeleton-graph .rectangular__skeleton__wrapper {
  padding: 8px;
}

.charger__history__logs__skeleton__wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.charger__history__logs__skeleton {
  display: flex;
  width: 100%;

}

.charger__history__logs__connector {
  width: 40px;
  height: 40px;
}

.charger__history__logs__connector .rectangular__skeleton__wrapper {
  padding: 4px;
}

.charger__history__logs__connector .rectangular__skeleton {
  border-radius: 4px;
}

.charger__history__logs__connector-graph {
  height: 40px;
  width: 100%;
}


.charger__history__logs__connector-graph .rectangular__skeleton__wrapper {
  padding: 4px;

}

.charger__sub__header__details__skeleton__wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 70px;
  column-gap: 10px;
}

.charger__sub__header__details__skeleton__wrap_full {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  height: 70px;
  column-gap: 10px;
}

.charger__sub__header__details__skeleton__wrap .rectangular__skeleton__wrapper {
  padding: 0px;

}

.charger__sub__header__details__skeleton__wrap .rectangular__skeleton {
  border-radius: 8px;
}

.charger__status__parameters__skeleton__wrap {
  display: flex;
  flex-direction: column;
}


.charger__status__parameters__skeleton {
  background-color: var(--white-color);
}

.charger__status__parameters__skeleton__wrap .rectangular__skeleton {
  height: 40px !important;
  border-radius: 6px;
}