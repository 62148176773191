.edit__history__wrap {
    padding: 16px 20px;
    min-height: calc(100dvh - 48px);
    height: calc(100dvh - 48px);
    display: flex;
    flex-direction: column;
}

.edit__history__wrap-header-link-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.edit__history__wrap .rms__table__dashboard__search__wrap-right {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
}

.edit__history__wrap .rms__table__dashboard {
    padding: unset;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}