.update__log__individual__row {
    display: flex;
    gap: 16px;
    align-items: center;
    font-size: 14px;
}

.update__log__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 20px;
}  

.update__log__no__data {
    display: flex;
    justify-content: center;
}

