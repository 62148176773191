.role__form__wrap {
    padding: 16px 20px;
    min-height: calc(100dvh - 48px);
    height: calc(100dvh - 48px);
    display: flex;
    flex-direction: column;
}

.role__form__wrap-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15px;
}

.role__form__wrap-content {
    margin: 10px 0px;
    height: 100%;
    overflow-y: auto;
}

.role__form__wrap-content .custom__form__wrap {
    padding: unset;
    min-height: auto;
    height: auto;
}

.role__form__wrap-footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    position: relative;
    width: 100%;
    padding-top: 10px;
}

.role_form_screen_operations_component {
    display: flex;
    flex-direction: column;
}

.role__form__wrap .rms__custom__text__area:focus-visible {
    outline: none;
}

.role__form__wrap .rms__custom__text__area:disabled {
    background-color: hsl(0, 0%, 95%);
    color: hsl(0, 0%, 60%);
}