.rule_engine_info_text{
    font-size: 12px;
    color: var(--text-grey-color);
}
.rms_table_rule_engine_nav_bar_status_container{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}
.rms_table_rule_engine_nav_bar_status_container .horizontal__navbar__container{
    margin-top: 16px;
}

.rms_table_rule_engine_nav_bar_status_container .horizontal__nav.selected{
    margin-bottom: 10px;
}

.manage__rule_engine__filters .side__filter__wrap-content-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
.manage__rule_engine__filters .side__filter__wrap-content .react-datepicker-wrapper {
    width: 100% !important;
}

/* .rule_engine_grid__container{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.rule_engine_all_rules_list{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
} */

.rul_engine_card__container{
    max-width: 275px;
    min-width: 246px;
    height: 140px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color:'#ffffff';
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 18px
}

.rule_engine_single_card{
 padding: 16px 11px;
}

.rule_engine_card_header{
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.rule_engine_card_body{
    font-size: 12px;
    font-weight: 400px;
    color:  #565656;
     height: auto;
}


.rule_engine_card_rule_name_container{
    flex-grow: 1;
    margin-left: 2px;
 
}

.rule_engine_card_rule_name {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.rule_engine_card_status_delete_container{
  display: flex;
  align-items: center;
}
.rule_engine_card_footer_display_action_buttons {
    display: flex;
    gap: 8px; 
    overflow-x: auto;
    padding-bottom: 8px; 
  }
.rule_engine_card_actionButton{
  width: auto;
  height: 22px;
  padding: 2px 2px;
  background-color: #f0f0f0;
  font-size: 11px;
  font-weight: 400;
  border-radius: 6px;
  border: 1px solid  #D9D9D9;
  white-space: nowrap; 
  color:#8C8C8C;
  ;
}

.rule_engine_draft_rules_list{
    display: flex;
    overflow-x: auto;
}

  .rule_engine_card_draft_scroll_controls {
    display: flex;
    align-items: center;
    position: relative;
  }
  .rule_engine_card_draft_scroll_arrow {
    position: absolute; 
    top: 50%; 
    transform: translateY(-50%); 
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  

  .rule_engine_card_draft_scroll_arrow:hover {
    background-color: #f0f0f0;
  }
  

  .rule_engine_card_draft_left_arrow {
    left: 0px; 
  }
  
  .rule_engine_card_draft_right_arrow {
    right: 0px; 
  }
.rule__engine__container__sub__header__left .horizontal__nav {
    font-size: 16px;
  }
  
.rule__engine__container__sub__header__left .horizontal__nav.selected {
    font-weight: 600;
  }

.rule__engine__highlighted__text{
  /* font-family: var(--font-family);
  color: var(--secondary-blue-color);
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  /* cursor: pointer; */


    color: var(--secondary-blue-color);
    font-size: 12px;
    font-family: var(--font-family-regular);
    letter-spacing: -0.01px;
    font-weight: 500;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
}

.rule__engine__column__normal__text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
}