input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.schedule__execution__side__popover_boxes{
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px 0px;
}

.schedule__execution__box{
    border: 1px solid var(--border-color);
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 48%;
    position: relative;
} 

.schedule__execution__box.selected {
    border: 1px solid var(--secondary-blue-color);
    background-color: #F7FFFF; ;
  }

.checkmark {
    position: absolute;
    background-position:-623px -744px;
    height: 20px;
    width: 20px;
    top: -8px;
    right: -8px;
    color: #00BFBF;
    font-size: 20px;
  }

.schedule__execution__days__wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 12px 0px;
}

.schedule__execution__day__box {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 4px 22px;
    cursor: pointer;
    font-size: 12px;
    color: var(--border-color3);
    display: flex;
    align-items: center;

}

.schedule__execution__day__box:hover{
    border-color: var(--secondary-blue-color); 
    color: var(--secondary-blue-color);
    background-color: #F7FFFF; 
}

.schedule__execution__day__box.selected{
    border-color: var(--secondary-blue-color); 
    color: var(--secondary-blue-color); 
    background-color: #F7FFFF; 
}

.schedule__execution__recurring__wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.schedule__execution__selected__days__wrapper{
    display: flex;
    flex-direction: row;
    gap: 2px;

}

.schedule__execution__selected__days {
    font-size: 14px;
}

.schedule__execution__recurrence__range__options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0px;
}

.recurrence__range__option {
    display: flex;
    gap: 12px;
    font-size: 14px;
}

.recurrence__option__number{
    border: none;
    border-bottom: 1px solid black;
    width: 54px;
    outline: none;
    padding: 0px 6px;
}
.recurrence__option__date{
    align-items: center;
}

.recurrence__option__custome__date__picker{
    border: none;
}

.schedule__execution__one__time__options{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.schedule__execution__select__date__wrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.schedule__execution__label{
    font-size: 12px;
    font-family: var(--font-family-regular);
    color: var(--primary-text-color);
}

.schedule__execution__box__sub__heading{
    font-size: 12px;
    color: var(--border-color3);
}

/* .timer {
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid var(--total-alarm-color);
    padding: 2px 4px;
} */

.schedule__execution__select__time__wrapper{
    display: flex;
    flex-direction: column;
    gap:12px;
}

.accordion-summary-content .accordion-title {
    font-size: 16px;
}

.accordion_container .accordion-details{
    font-size: 14px;
}

.schedule__execution__recurring__repeat__option__error__message{
    font-size: 10px;
    font-weight: 500;
    font-family: var(--font-family);
    color: var(--red-color);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
    padding-top: 5px;
}

.schedule__execution__recurring__select__time__wrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
