.link__btn-icon {
  background: var(--sprite-sheet-url) no-repeat -1392px -78px;
  width: 20px;
  height: 16px;
}

.link__btn-icon.right {
  transform: rotate(180deg);
}

.link__btn-text {
  font-family: var(--font-family);
  color: var(--secondary-blue-color);
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
}

.add__station__back-btn_wrap {
  display: flex;
  align-items: center;
}

.add__station__back-btn_wrap.reverse {
    flex-direction: row-reverse;
}

.rms-back-btn {
  text-decoration: none;
}
