.rms__table__dashboard {
  padding: 16px 20px 0px 20px;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.rms__table__dashboard-header-wrap {
  display: flex;
  width: 100%;
  min-width: 100%;
  /* justify-content: space-between; */
  align-items: center;
  padding-bottom: 5px;
}

.rms__table__dashboard-header {
  display: inline;
}

.rms__table__dashboard-sub-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: auto;
  gap: 10px;
}

.rms__table__dashboard-sub-header-wrap-buttons {
  display: flex;
  height: 32px;
  gap: 10px;
}

.add__new__btn {
  /* margin-right: 10px !important; */
  background-color: var(--primary-white-color) !important;
}

.create_new_rule_btn {
  /* margin-right: 10px !important; */
  background-color: var(--primary-white-color) !important;
}

.create_new_rule_custom {
  /* margin-right: 10px !important; */
  background-color: white;
}

.rms__table__dashboard__view__options__wrap {
  display: flex;
}

.rms__table__dashboard__search__wrap {
  padding: 15px 0px;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
}

.rms__table__dashboard__search__wrap-right {
  display: grid;
  grid-template-columns: repeat(7, minmax(140px, 140px));
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.rms__table__dashboard__search__wrap-right .custom__date__picker__popper {
  left: -30px !important;
}

.rms__table__dashboard-navbar-wrap {
  display: flex;
  padding-bottom: 5px;
}

.rms__table__dashboard-navbar-wrap .horizontal__navbar__container {
  border-bottom: 0px;
}

.rms__table__dashboard__table__wrap {
  display: flex;
  overflow: hidden;
  min-height: 300px;
}

.rms__table__dashboard__footer {
  padding-top: 20px;
  color: var(--placeholder-color);
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.3px;
}

.rms__sort__icon__wrap {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rms__table__row__cards {
  display: flex;
  flex-direction: column !important;
  border-bottom: 1px solid var(--mobile-view-border-color);
  background-color: var(--white-color);
  margin-bottom: 12px !important;
  margin-top: 12px !important;
  padding: 18px;
}

.rms__table__row__card-header-wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;

  justify-content: space-between;
}

.rms__table__row__card-content-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.rms__table__row__card-content-row {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  width: 100%;
  align-items: center;
}

.rms__table__row__card-content-row:first-of-type {
  padding-top: 0px;
}

.rms__table__row__card-header-right-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.add__filter__btn {
  /* margin-right: 10px !important; */
  color: var(--sub-header-text-color) !important;
}

.rms__table__row__card-header-row {
  display: flex;
  margin-bottom: 10px;
}

.rms__table__row__card-header-row:last-child {
  margin-bottom: 0px;
}

.rms__table__row__card-content-column {
  display: flex;
  width: 50%;
  align-items: center;
}

.rms__table__row__card-content-column-text {
  color: var(--mobile-view-content-text-color);
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 400;
  padding-left: 4px;
  display: flex;
  align-items: center;
}

.rms__table__dashboard__no__data {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
  flex-direction: column;
}

.rms__table__dashboard__no__data-text {
  padding-top: 8px;
}

.rms__table__dashboard__no__charger__data {
  padding-top: 8px;
  margin-right: 40px;
}

.tooltip__icon__wrapper {
  margin-right: 10px;
}

/* Pagination Component Style Starts */

.pagination__total__of__rows__component {
  white-space: pre;
  padding-right: 10px;
  font-size: 15px;
}

.pagination__no__of__rows__component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

.pagination__no__of__rows__component-page-count {
  color: var(--secondary-blue-color);
}

.pagination__no__of__rows__component-page-text {
  white-space: pre;
}

.pagination__goto__component {
  display: flex;
}

.pagination__goto__component-text {
  white-space: pre;
}

.pagination__goto__component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.pagination__no__of__rows__component-page-text {
  padding-left: 5px;
}

.pagination__goto__component-text {
  padding: 0px 5px;
}

.pagination__goto__component-input {
  width: 60px;
}

.table__footer__wrap-paginated-grid .MuiTablePagination-displayedRows {
  display: none !important;
}

.table__footer__wrap-paginated-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.table__footer__wrap-paginated-grid-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table__footer__wrap-paginated-grid .MuiToolbar-root {
  padding-left: 0px !important;
}

.table__footer__pagination {
  margin-left: 0px !important;
}

.toggle__switch__config {
  padding-top: 6px;
  display: flex;
  flex-direction: row;
}

.dasboard-toggle-switch-info {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-left: auto;
}