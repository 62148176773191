
.view-parameter-header {
    display: flex;
    padding-top: 15px;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.8vh;
  }
  .parameters-cards-summary-wrapper{

    padding-bottom: 3.5vh;
  }
  .set-parameter-view-search{
    padding-top:1.2vh ;
    padding-bottom: 2vh;
  }

  /* summary wrapper css */
  
  .parameters-cards-summary-wrapper .parameter_sub__header__grey__card {

    background-color: var(--grey-card-bg);
    padding: 10px;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
    width:100%;
  }
  
  .parameters-cards-summary-wrapper .parameter_sub__header__grey__card__content__wrap {
    display: grid;
    grid-template-columns: repeat(6, minmax(200px, 1fr));
    align-items: center;
    justify-content:space-between;
    padding: 0px 10px;

  }

  .parameters-cards-summary-wrapper .parameter_sub__header__grey__card__content-title {
    font-size: 12px;
    font-family: var(--font-family-regular)!important;
    color: var(--primary-text-color);
    letter-spacing: 0.2px;
   font-weight: 400;
  }

  .parameters-cards-summary-wrapper .parameter_sub__header__grey__card__content-value {
    margin-top: 5px;
    font-size: 14px;
    font-family: var(--font-family-semi-bold)!important;
    color: var(--primary-text-color);
    letter-spacing: -0.01px;
    font-weight: 500;
    padding-right: 5.5vw;
  }
  
  @media only screen and (max-width: 700px) and (min-width: 480px) {
    .parameters-cards-summary-wrapper .parameter_sub__header__grey__card__content__wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content:space-between;
      flex-basis: auto;
  
    }
  }

  @media only screen and (max-width: 480px) and (min-width: 375px) {
  .parameters-cards-summary-wrapper .parameter_sub__header__grey__card__content__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content:space-between;
    flex-basis: auto;

  }
}

.parameter_sub__header__grey__card .rms__table__status__wrap{
justify-content: flex-start;

}