.rms__toggle__switch__container {
	display: flex;
	/* margin: 0px 15px; */
}

.rms__toggle__switch__container  label {
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 12px;
	font-weight: 600;
}

.rms__toggle__switch__container .rms__toggle__switch__container-enabled-text {
	color: var(--enable-toggle-text-color);
}

.rms__toggle__switch__container .rms__toggle__switch__container-disabled-text {
	color: var(--disable-toggle-text-color)
}

.rms__toggle__switch {
	padding-left: 10px;
}
.rms__toggle__switch .react-switch-handle {
	background: transparent !important;
}