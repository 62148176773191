.user__form__wrap {
  padding: 16px 20px;
  min-height: calc(100dvh - 48px);
  height: calc(100dvh - 48px);
  display: flex;
  flex-direction: column;
}

.user__form__wrap-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}

.user__form__wrap-header-custom {
  display: flex;
  align-items: center;
  padding-top: 15px;
  gap: 7px;
}

.user__form__wrap-content {
  margin: 10px 0px;
  height: 100%;
  overflow-y: auto;
}

.user__form__wrap-content .custom__form__wrap {
  padding: unset;
  min-height: auto;
  height: auto;
}

.user__form__wrap-footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  position: relative;
  width: 100%;
  padding-top: 10px;
}

.user__form__back__btn {
  width: fit-content;
}

.user__form-mobile-input {
  height: 32px !important;
}

.user__form_header-status-info {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-left: auto;
}

.user__form_header-status-info-custom {
  display: flex;
  align-items: center;
  padding: 5px;
}

.user__form__wrap-footer-top {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-bottom: 10px;
  color: var(--primary-text-color);
  margin-top: 20px;
}

.user__form__wrap-footer-top .MuiCheckbox-root {
  padding-right: 5px;
}

.user__form__wrap-footer-top .user__form_footer-auto-assign-info {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.user__form_header-edit {
  display: flex !important;
  align-items: center;
  padding: 5px !important;
}