.rule__engine__form__schedule__execution_wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 25px 0px 0px 0px;
}

.rule__engie__form__schedule__execution__summary {
    flex-direction: column;
    display: flex;
    gap: 8px;

}

.rule__engine__form__schedule__execution__label {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.rule__engine__row__label {
    color: var(--primary-text-color);
    font-weight: 500;
    font-family: sans-serif;
}

.rule__engine__form__schedule__row__summary__value {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.rule__engine__form__execution__type__time {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.rule__engine__form__excution__subheading {
    font-size: 16px;
    font-weight: 600;
}

.rule__engine__form__recurring__days__range__wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.rule__engine__form__schedule__execution__type__wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px
}

.rule__engine__form__schedule__execution__label .edit__button {
    cursor: pointer;
}

.rule__engine__charger_selection {
    background-color: white;
}

.ruleEngine__form__wrap-header-custom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;

}

.rule__name__status{
    display: flex;
    flex-direction: row;
    gap: 8px
}

.side__popover__container-paper.rule__engine__form__side__popover__expanded{
    width: 100vw;
    max-width: 100vw;
    left: 0 !important;
}