.configure__notification__detail__popover {
   padding: 24px !important;
}

.side__popover__container-paper.configure-notification{
    width: 100vw;
    max-width: 100vw;
    left: 0 !important;
}

.side__popover__container-paper.configure-notification-not-expandable{
  width: 680px;
}


.configure__notification__alarm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.configure__notification__dashboard__link__text{
    color: #3dcdcd;
    font-size: 12px;
    font-family: var(--font-family-regular);
    letter-spacing: -0.01px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
}

.configure__notification__dashboard__text{
    color: var(--primary-text-color);
    font-size: 12px;
    font-family: var(--font-family-regular);
    letter-spacing: -0.01px;
    font-weight: 500;
    width: 100%;
}

.configure__notification__dashboard__action__icons__wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}

.configure__notification__container__sub__header {
    display: flex;
}

.configure__notification__container__sub__header__left .horizontal__nav {
    font-size: 16px;
  }
  
.configure__notification__container__sub__header__left .horizontal__nav.selected {
    font-weight: 600;
  }

  .configure__notification__container__sub__header__left .horizontal__navbar__container {
    border-bottom: 0px;
  }

  /* .configure__notification__dashboard__link__text .custom__tooltip__label__wrap .text__label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  } */


.rule_engine_card__container.configure_notification_card{
    gap: 5px !important;
}

.configure__notification .custom-resize {
  min-height: 32px !important;
  height: 32px !important;
  padding: 8px 12px 0px !important;
}


