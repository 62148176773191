.trigger__point__operator__value__wrapper{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.trigger__point__side__popover__wrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px 0px;
}

.trigger__point__side__popover__value__label {
    display: flex;
    gap: 8px;
    align-items: baseline;
}