/* Table Style Starts...... */
.manage__customer__table .rms__table__dashboard__search__wrap-right {
  grid-template-columns: repeat(1,minmax(180px,1fr));
}

.charger__connector__type__cell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.charger__connector__type__text {
  font-size: 12px;
  font-family: var(--font-family-regular);
  letter-spacing: -0.01px;
  font-weight: 400;
  display: flex;
}

.charger__connector__type__line {
  width: 100%;
  height: 4px;
}

.charging__station__action__icons__wrap .action__icon__btn {
  margin-right: 0px !important;
}

.customer__dashboard_link-text{
  color: #3dcdcd;
  font-size: 12px;
  font-family: var(--font-family-regular);
  letter-spacing: -0.01px;
  font-weight: 500;
  cursor: pointer;
}