/* Table Style Starts...... */
.manage__chargers__table .individual__charger__wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.manage__chargers__table .individual__charger__wrap .add__station__back-btn_wrap .link__btn-text {
  font-size: 12px;
  font-family: var(--font-family-regular);
  letter-spacing: -0.01px;
  font-weight: 500;
}

.charger__connector__type__cell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.charger__connector__type__text {
  font-size: 12px;
  font-family: var(--font-family-regular);
  letter-spacing: -0.01px;
  font-weight: 400;
  display: flex;
}

.charger__connector__type__line {
  width: 100%;
  height: 4px;
}

.charging__station__action__icons__wrap .action__icon__btn {
  margin-right: 0px !important;
  padding-left: 12px;
}

.charger__broadcast__message__panel {
  width: 100%;
  padding: 16px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

}

.broadcast_message_radio_button_wrap{
  margin-top: 2%;
  margin-left: 1%;
  border-bottom: 1px solid var(--grey-card-bg);
}



/* CSS of SetParametersDashboard */

.individual__user__text_set_parameter {
  color: #3dcdcd;
  font-size: 12px;
  font-family: var(--font-family-regular);
  letter-spacing: -0.01px;
  font-weight: 500;
  cursor: pointer;
}

.rms__table__dashboard__search__wrap-right.grid-6 {
  grid-template-columns: 160px 130px 130px 130px 130px 130px;
}

.rms__table__dashboard__search__wrap-right.grid-7 {
  grid-template-columns: 140px 130px 130px 130px 130px 130px 130px;
}

.export__option__dropdown_bulk .MuiList-root{
  padding: 0px !important;
  width: 110px !important;
}
.manage__chargers__side__filters .side__filter__wrap-content-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}