/*Individual Charger Style Starts...... */

.charger__panel {
  width: 100%;
  padding: 16px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

}

.back__btn-container {
  width: max-content;
}

.charger__panel-sub__header-cards {
  width: 100%;
}

.charger__panel-detail-navbar{
  display: flex;
  justify-content: space-between;
}

.charger__panel__expand__and__collapse__wrap{
  width: 55px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  border-bottom: 1px solid var(--grey-card-bg);
  justify-content: space-between;
}

.expand__and__collapse__text:first-of-type {
  margin-right: 10px;
}

.expand__and__collapse__text {
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--primary-text-color);
  letter-spacing: -0.01px;
  cursor: pointer;
  border: 0px;
  background-color: transparent;

}

.expand__and__collapse__text:hover{
  color: var(--secondary-blue-color);
}

.charger__panel-cards-wrap {
  padding: 10px 0px;
}

.charger__panel-header {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
  align-items: center;
}

.charger__panel-sub-header-wrap {
  display: flex;
}

.charger__panel-header-content-wrap {
  display: flex;
  align-items: center;
}

.charger__panel-header .app__dashboard__header {
  padding-right: 15px;
}

.charger__panel-refresh-btn {
  color: var(--sub-header-text-color) !important;
  background: var(--white-color) !important;
  font-size: 14px !important;
  line-height: 1 !important;
  border-radius: 4px !important;
  padding: 8px 16px !important;
}

.charger__panel-refresh-btn:hover {
  background: var(--primary-white-color) !important;
}

.charger__panel-submit-btn {
  padding: 0px;
}

.charger__panel-sub-header {
  color: var(--primary-text-color);
  font-size: 14px;
  font-family: var(--font-family-regular);
  padding: 0px 15px;
  white-space: nowrap;
  text-align: center;
}

.charger__panel-sub-header-separator {
  color: var(--border-color3);
  font-size: 12px;
  font-family: var(--font-family-regular);
}

.charger__panel-alarm-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minmax(200px,1fr));
  padding-bottom: 20px;
  column-gap: 10px;
}


/* ChargerPanelSettingsDashboard styles start ............. */

.charger__panel__settings_dashboard-header {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.charger__panel__settings_dashboard__nav__btn-wrapper {
  padding-right: 10px;
  padding-top: 10px;
}

.charger__panel__settings_dashboard__nav__btn-wrapper.selected .charger__panel__settings_dashboard__nav__btn {
  background-color: var(--grey-color) !important;
  font-weight: 600;
}

.charger__panel__settings_dashboard__nav__btn {
  background-color: var(--btn-nav-color) !important;
}

.charger__panel__settings_dashboard__nav__btn:hover {
  background-color: var(--btn-nav-hover-color) !important;

}

.charger__panel__table__operation__cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.charger__panel__table__operation__text {
  padding: 4px 12px;
  background-color: var(--secondary-blue-color);
  border-radius: 4px;
  color: var(--white-color);
  font-family: var(--font-family-regular);
  font-size: 12px;
  letter-spacing: -0.01px;
}

/* ChargerPanelRemoteCommandsDashboard styles start ............. */

.charger__remote__commands_dashboard-radio-buttons-wrap {
  border: 1px solid var(--border-color2);
  border-radius: 4px;
  padding: 10px 15px;
}

.charger__remote__commands_dashboard-content-wrap {
  display: flex;
  flex-direction: column;
}
.charger__remote__commands_dashboard-content-wrap-row{
  display: flex;
}

.charger__remote__commands_dashboard-footer-wrap {
  margin-top: 50px;
}

.charger__remote__commands_dashboard-content {
  padding-top: 10px;
  padding-right: 50px;
  width: 30%
}

.charger__remote__commands_dashboard-content .rms__custom__input__field__container {
  max-width: 350px;

}

/* ChargerPanelAlarmHistoryDashboard styles start ............. */

.alarms__dashboard__container-filter-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 0px 8px;
}

.charger__panel__admin__actions__dashboard {
  display: flex;
  flex-direction: column;
}

.charger__panel__systems__info__dashboard {
  padding: 16px 0px;
  overflow-y: auto;
}

.charger__panel__systems__info__dashboard .charger__status__toggle__wrap {
  cursor: pointer;
}